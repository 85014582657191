import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Label = ({ type, children, className, ...props }) => {
  const cls = classNames("label", type ? "label--" + type : "", className);

  return (
    <div className={cls} {...props}>
      {children}
    </div>
  );
};

Label.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(Label);
