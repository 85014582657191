import React, { Suspense } from "react";
import { Card, CardBody, CardTitle } from "@xixoio/components/Card";
import i18n from "i18n";
import PropTypes from "prop-types";

const Modal = React.lazy(() => import("@xixoio/components/Modal/Modal.jsx"));

const NonExistentBCWalletModal = ({ onClose }) => {
  return (
    <Suspense fallback={<></>}>
      <Modal
        isOpen
        onRequestClose={onClose}
        containerClassName="max-w-md w-full"
      >
        <Card>
          <CardTitle onClose={onClose}>
            {i18n.t("wallet.wallet_not_found")}
          </CardTitle>

          <CardBody>
            <p className="text-lg">{i18n.t("wallet.text")}</p>
          </CardBody>
        </Card>
      </Modal>
    </Suspense>
  );
};

NonExistentBCWalletModal.path = "/non-existent-bc-wallet";

NonExistentBCWalletModal.composePath = () => "non-existent-bc-wallet";

NonExistentBCWalletModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NonExistentBCWalletModal;
