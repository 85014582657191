import React from "react";
import { RemoveScroll } from "react-remove-scroll";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useBreakpoint } from "../../responsive";

const NotificationsPanel = ({
  notifications,
  opened,
  hideAll,
  className,
  texts,
}) => {
  const { isTablet } = useBreakpoint();

  return (
    <RemoveScroll enabled={isTablet && opened}>
      <div
        className={classNames(
          "notifications__panel",
          {
            show: opened,
          },
          className
        )}
      >
        <div className="notifications__list-header">
          <span className="font-semibold text-lg">{texts.title}</span>
          {notifications.some((notif) => notif.onHide) && (
            <button className="link" onClick={hideAll}>
              {texts.markAllAsRead}
            </button>
          )}
        </div>
        <ul className="notifications__list">
          {notifications.map((notif, index) => notif.render(index))}
          {notifications.length === 0 && (
            <li className="notifications__list-item">
              <span className="text-gray-200">{texts.noNotifications}</span>
            </li>
          )}
        </ul>
      </div>
    </RemoveScroll>
  );
};

NotificationsPanel.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      onHide: PropTypes.func,
      render: PropTypes.func,
    })
  ),
  opened: PropTypes.bool,
  hideAll: PropTypes.func,
  texts: PropTypes.shape({
    title: PropTypes.string,
    markAllAsRead: PropTypes.string,
    noNotifications: PropTypes.string,
  }),
};

NotificationsPanel.defaultProps = {
  texts: {
    title: "Upozornění",
    markAllAsRead: "Označit jako přečtené",
    noNotifications: "Nemáte žádné upozornění",
  },
};

export default NotificationsPanel;
