import React, { Suspense, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { GET_RATE } from "queries/buying";
import { LIST_CLIENT_TRANSACTIONS } from "queries/transactions";
import { useModal } from "utils/hooks";

import PaymentDetailModal from "./PaymentDetailModal";

const ClientRouteProvider = React.lazy(() =>
  import(
    "containers/clients/ClientRouteProvider" /* webpackChunkName: "ClientRouteProvider" */
  )
);
const BuyRouteProxy = React.lazy(() =>
  import(
    "components/buying/BuyRouteProxy" /* webpackChunkName: "BuyRouteProxy" */
  )
);

const BuyOnBehalfModal = ({
  token,
  currency,
  onBehalfOf,
  min,
  max,
  tokensLeftFiat,
  onClose,
}) => {
  const { data } = useQuery(GET_RATE, {
    variables: {
      currency: "CZK",
      token,
    },
  });

  const showPaymentDetail = useModal(PaymentDetailModal);
  const navigate = useNavigate();
  const location = useLocation();

  const onBuyScreenChange = useCallback(
    (screen) => {
      const params = new URLSearchParams(location.search);
      params.set("screen", screen);
      navigate(`${location.pathname}?${params.toString()}`);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [navigate, location.pathname]
  );

  max = parseFloat(max) || 0;
  tokensLeftFiat = parseFloat(tokensLeftFiat) || 0;
  const maxOrderLimit = Math.min(max, tokensLeftFiat);

  // FIXME: avoid hardcoding/duplication of the query to be refetched. Should be
  // solved by GraphQL subscriptions in the future.
  const mutationOptions = {
    refetchQueries: [
      {
        query: LIST_CLIENT_TRANSACTIONS,
        variables: {
          perPage: 5,
          clientId: onBehalfOf,
        },
      },
    ],
  };

  if (!data) {
    return <></>;
  }

  return (
    <Suspense fallback={<></>}>
      <ClientRouteProvider
        component={BuyRouteProxy}
        clientId={onBehalfOf}
        passAs="onBehalfOf"
        routeParams={{
          token,
          currency,
          min: min * (1 + data.transactionPurchaseFeePercent / 100),
          max: maxOrderLimit * (1 + data.transactionPurchaseFeePercent / 100),
        }}
        onClose={onClose}
        onTransactionComplete={showPaymentDetail}
        onBuyScreenChange={onBuyScreenChange}
        mutationOptions={mutationOptions}
      />
    </Suspense>
  );
};

BuyOnBehalfModal.path =
  "/buy/:onBehalfOf/:token/:currency/:min/:max/:tokensLeftFiat";

BuyOnBehalfModal.composePath = (
  onBehalfOf,
  token,
  currency,
  min,
  max,
  tokensLeftFiat
) => `buy/${onBehalfOf}/${token}/${currency}/${min}/${max}/${tokensLeftFiat}`;

BuyOnBehalfModal.propTypes = {
  token: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  onBehalfOf: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BuyOnBehalfModal;
