import React from "react";
import classNames from "classnames";

const CardBody = ({ children, className }) => {
  const cls = classNames("card__body", className);

  return <div className={cls}>{children}</div>;
};

export default React.memo(CardBody);
