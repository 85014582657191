import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Icon } from "../Icon";
import { Spinner } from "../Spinner";

const Button = React.forwardRef(
  (
    {
      type = "button",
      className,
      primary,
      secondary,
      danger,
      textCenter,
      link,
      loading,
      small,
      large,
      icon,
      iconLeft,
      iconRight,
      text,
      centered,
      children,
      routerTo,
      ...props
    } = {},
    ref
  ) => {
    const isString = (what) => {
      return typeof what === "string" || what instanceof String;
    };

    const cls = classNames(
      "btn",
      {
        "btn--primary": !!primary,
        "btn--secondary": !!secondary,
        "btn--danger": !!danger,
        "text-center": !!textCenter,
        "btn--link": !!link,
        "btn--loading": loading,
        "btn--sm": !!small,
        "btn--lg": !!large,
        "btn--icon": !!icon,
        "btn--text": !!text,
        "btn--centered": !!centered,
      },
      iconLeft
        ? isString(iconLeft)
          ? iconLeft + ":btn--icon-left"
          : "btn--icon-left"
        : null,
      iconRight
        ? isString(iconRight)
          ? iconRight + ":btn--icon-right"
          : "btn--icon-right"
        : null,
      className
    );

    const clsSpinner = classNames({
      "stroke-current text-white-100": !!primary || !!danger,
      "h-6 w-6": (!small && !large) || !!large,
      "h-3 w-3": !!small,
    });

    const clsSpinnerWrapper = classNames({
      "px-6 py-2": !small && !large,
      "px-4 py-2": !!small,
      "px-8 py-3": !!large,
    });

    const inner = (
      <>
        {loading && (
          <span className={clsSpinnerWrapper}>
            <Spinner className={clsSpinner} />
          </span>
        )}
        {!loading && (
          <>
            {icon && !iconRight && <Icon icon={icon} className="btn__icon" />}
            {children && <span className="btn__label">{children}</span>}
            {iconRight && <Icon icon={icon} className="btn__icon" />}
          </>
        )}
      </>
    );

    if (routerTo) {
      return (
        <NavLink
          ref={ref}
          to={routerTo}
          disabled={loading}
          className={cls}
          {...props}
        >
          {inner}
        </NavLink>
      );
    }

    const Tag = props.href ? "a" : "button";

    if (!props.href) {
      props.type = type;
    }

    return (
      <Tag ref={ref} className={cls} disabled={loading} {...props}>
        {inner}
      </Tag>
    );
  }
);

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  routerTo: PropTypes.string,
  /**
   * Main action button type
   */
  primary: PropTypes.bool,
  /**
   * Secondary action button type
   */
  secondary: PropTypes.bool,
  /**
   * Destuctive action button type
   */
  danger: PropTypes.bool,
  /**
   * Centered button
   */
  centered: PropTypes.bool,
  /**
   * Button with centered text
   */
  textCenter: PropTypes.bool,
  /**
   * Button behaving like a text
   */
  text: PropTypes.bool,
  /**
   * Button behaving like a link
   */
  link: PropTypes.bool,
  /**
   * Loading state of the button
   */
  loading: PropTypes.bool,
  /**
   * Small size of the button
   */
  small: PropTypes.bool,
  /**
   * Large size of the button
   */
  large: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.string,
  iconRight: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  iconLeft: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default React.memo(Button);
