import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Icon } from "../Icon";

const CircleIcon = ({ className, icon, children, ...props }) => {
  const cls = classNames("circle-icon", className);

  return (
    <span className={cls} {...props}>
      <Icon icon={icon} />
      {children}
    </span>
  );
};

CircleIcon.propTypes = {
  className: PropTypes.string,
  /**
   * Icon classname
   */
  icon: PropTypes.string,
  children: PropTypes.node,
};

export default CircleIcon;
