import React, { useCallback, useMemo } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

export const QUERY_PARAM = "modal";

/**
 * Spawn the modal.
 *
 * The actual modal spawner needs to be memoized to be protected for query
 * param changes in the modal handlers themselves. They might need
 * to alter some other query params apart from the `QUERY_PARAM` without
 * the full modal rerender.
 */
const ModalSpawner = React.memo(({ modals, path, onClose }) => {
  for (const Modal of modals) {
    if (path) {
      const match = matchPath(Modal.path, path);
      if (match) {
        return <Modal onClose={onClose} {...match.params} />;
      }
    }
  }
  return null;
});

const ModalRenderer = ({ modals }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const modalPath = decodeURIComponent(params.get(QUERY_PARAM));

  /* Memoize the callback to prevent needless rerenders of ModalSpawner */
  const hideModals = useCallback(() => {
    params.delete(QUERY_PARAM);
    navigate({
      search: params.toString() !== "" ? "?" + params.toString() : null,
    });
  }, [navigate, params]);

  if (!modalPath || !modals || !modals.length) {
    // no modal to display
    return null;
  }

  return (
    <ModalSpawner modals={modals} path={`/${modalPath}`} onClose={hideModals} />
  );
};

export default ModalRenderer;
