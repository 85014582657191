import React from "react";
import i18n from "i18n";

const Footer = () => {
  return (
    <div className="bg-black-900 text-grey-300 font-normal py-8 lg:pt-12 lg:pb-20">
      <div className="container">
        <div className="lg:flex space-y-4 lg:space-y-0 justify-between w-full px-1 lg:px-0 lg:text-center">
          <svg
            className="inline-block mb-4"
            width="97"
            height="21"
            viewBox="0 0 97 21"
            fill="none"
          >
            <path
              d="M22.284 20.0687C21.8624 20.0687 21.458 19.892 21.1598 19.5773C20.8617 19.2626 20.6941 18.8358 20.6941 18.3908V1.67799C20.6925 1.45651 20.7323 1.23685 20.8115 1.03172C20.8906 0.826585 21.0075 0.640009 21.1553 0.48277C21.3031 0.32553 21.479 0.200735 21.6727 0.115567C21.8664 0.0303991 22.0742 -0.0134583 22.2841 -0.0134583C22.4939 -0.0134583 22.7017 0.0303991 22.8955 0.115567C23.0892 0.200735 23.265 0.32553 23.4128 0.48277C23.5606 0.640009 23.6775 0.826585 23.7567 1.03172C23.8358 1.23685 23.8757 1.45651 23.874 1.67799V18.391C23.874 18.836 23.7064 19.2628 23.4082 19.5774C23.11 19.892 22.7057 20.0687 22.284 20.0687Z"
              fill="white"
            />
            <path
              d="M72.1251 20.0687C71.7035 20.0687 71.2991 19.892 71.001 19.5773C70.7028 19.2626 70.5353 18.8358 70.5353 18.3908V1.67799C70.5336 1.45651 70.5735 1.23685 70.6526 1.03172C70.7318 0.826585 70.8486 0.640009 70.9964 0.48277C71.1442 0.32553 71.3201 0.200735 71.5138 0.115567C71.7075 0.0303991 71.9153 -0.0134583 72.1252 -0.0134583C72.3351 -0.0134583 72.5428 0.0303991 72.7366 0.115567C72.9303 0.200735 73.1061 0.32553 73.254 0.48277C73.4018 0.640009 73.5186 0.826585 73.5978 1.03172C73.6769 1.23685 73.7168 1.45651 73.7151 1.67799V18.391C73.7151 18.836 73.5475 19.2628 73.2493 19.5774C72.9512 19.892 72.5468 20.0687 72.1251 20.0687Z"
              fill="white"
            />
            <path
              d="M1.58891 20.0778C1.29058 20.0776 0.998338 19.9888 0.745555 19.8216C0.492773 19.6543 0.28966 19.4155 0.159446 19.1322C0.0292333 18.8489 -0.022839 18.5327 0.00918999 18.2196C0.041219 17.9066 0.156064 17.6094 0.340577 17.362L12.8055 0.649157C12.9346 0.476004 13.0948 0.331354 13.2769 0.223535C13.459 0.115717 13.6594 0.046835 13.8668 0.020766C14.0741 -0.00530311 14.2842 0.0119529 14.4852 0.0716021C14.6862 0.131251 14.8741 0.232121 15.0382 0.368408C15.2023 0.504694 15.3393 0.673768 15.4415 0.865943C15.5437 1.05812 15.6089 1.26962 15.6336 1.48842C15.6583 1.70723 15.642 1.92906 15.5855 2.14119C15.5289 2.35332 15.4334 2.5516 15.3042 2.72476L2.83933 19.4376C2.69058 19.6374 2.50068 19.7989 2.28405 19.9098C2.06742 20.0207 1.82969 20.0781 1.58891 20.0778Z"
              fill="white"
            />
            <path
              d="M14.065 20.0689C13.8242 20.0693 13.5864 20.0118 13.3698 19.9009C13.1532 19.79 12.9633 19.6284 12.8146 19.4286L0.349669 2.71577C0.220532 2.54262 0.124956 2.34432 0.0684334 2.1322C0.0119109 1.92008 -0.00443264 1.69829 0.0202641 1.47949C0.0449609 1.26069 0.11024 1.04913 0.212396 0.856954C0.314552 0.664782 0.451577 0.495757 0.61564 0.359471C0.779703 0.223185 0.967602 0.122317 1.1686 0.0626653C1.36959 0.00301348 1.57974 -0.0142872 1.78707 0.0117769C1.99439 0.037841 2.1948 0.106786 2.37689 0.214598C2.55898 0.322411 2.71919 0.467022 2.84833 0.640168L15.3132 17.353C15.4977 17.6004 15.6125 17.8976 15.6445 18.2106C15.6765 18.5237 15.6244 18.8399 15.4942 19.1231C15.364 19.4064 15.1609 19.6453 14.9082 19.8125C14.6554 19.9797 14.3632 20.0685 14.0649 20.0688L14.065 20.0689Z"
              fill="white"
            />
            <path
              d="M30.517 20.069C30.2186 20.0687 29.9264 19.9799 29.6737 19.8127C29.4209 19.6455 29.2178 19.4066 29.0876 19.1233C28.9574 18.8401 28.9053 18.5239 28.9373 18.2108C28.9694 17.8978 29.0842 17.6006 29.2686 17.3532L41.7341 0.640265C41.9949 0.290579 42.3767 0.0645123 42.7954 0.0118735C43.2141 -0.0407653 43.6354 0.0843249 43.9668 0.359568C44.2981 0.63481 44.5123 1.03769 44.5622 1.47958C44.612 1.92147 44.4936 2.36618 44.2328 2.71586L31.7675 19.4287C31.6188 19.6285 31.4289 19.7901 31.2122 19.901C30.9955 20.0119 30.7578 20.0694 30.517 20.069Z"
              fill="white"
            />
            <path
              d="M42.9791 20.069C42.7383 20.0693 42.5006 20.0119 42.284 19.9009C42.0673 19.79 41.8775 19.6285 41.7287 19.4287L29.2638 2.71586C29.1347 2.54271 29.0391 2.34441 28.9826 2.13229C28.9261 1.92017 28.9097 1.69838 28.9344 1.47958C28.9591 1.26078 29.0244 1.04922 29.1265 0.857044C29.2287 0.664871 29.3657 0.495847 29.5298 0.359561C29.6938 0.223274 29.8817 0.122407 30.0827 0.062755C30.2837 0.00310312 30.4939 -0.0141976 30.7012 0.0118665C30.9085 0.0379306 31.109 0.106876 31.2911 0.214688C31.4731 0.3225 31.6333 0.467111 31.7624 0.640258L44.2273 17.3531C44.4118 17.6005 44.5266 17.8977 44.5586 18.2107C44.5906 18.5238 44.5386 18.84 44.4084 19.1232C44.2782 19.4065 44.0751 19.6454 43.8223 19.8126C43.5695 19.9798 43.2773 20.0686 42.979 20.0689L42.9791 20.069Z"
              fill="white"
            />
            <path
              d="M56.7583 20.0688C54.8778 20.0688 53.0395 19.4802 51.476 18.3777C49.9124 17.2751 48.6938 15.708 47.9741 13.8744C47.2545 12.0409 47.0662 10.0233 47.4331 8.07685C47.7999 6.13038 48.7054 4.34243 50.0351 2.9391C51.3649 1.53578 53.059 0.580081 54.9034 0.192905C56.7477 -0.194272 58.6595 0.00444842 60.3968 0.763923C62.1342 1.5234 63.6191 2.80956 64.6639 4.45969C65.7086 6.10983 66.2662 8.04986 66.2662 10.0345C66.2634 12.6948 65.2607 15.2453 63.4782 17.1264C61.6958 19.0076 59.2791 20.0657 56.7583 20.0688ZM56.7583 3.35594C55.5067 3.35594 54.2832 3.7476 53.2425 4.48145C52.2019 5.21529 51.3908 6.25835 50.9118 7.47869C50.4328 8.69903 50.3076 10.0419 50.5517 11.3374C50.7959 12.6329 51.3986 13.8228 52.2836 14.7568C53.1686 15.6908 54.2962 16.327 55.5237 16.5846C56.7513 16.8423 58.0237 16.7101 59.18 16.2046C60.3363 15.6991 61.3246 14.8431 62.02 13.7448C62.7153 12.6465 63.0865 11.3554 63.0865 10.0345C63.0846 8.26383 62.4172 6.56626 61.2309 5.31423C60.0445 4.0622 58.436 3.35795 56.7583 3.35594Z"
              fill="white"
            />
            <path
              d="M87.4919 20.0687C85.6114 20.0687 83.7732 19.4802 82.2096 18.3776C80.6461 17.275 79.4275 15.7078 78.7079 13.8743C77.9882 12.0408 77.8 10.0233 78.1668 8.07679C78.5337 6.13033 79.4393 4.34236 80.769 2.93905C82.0987 1.53573 83.7928 0.580069 85.6372 0.192901C87.4815 -0.194266 89.3932 0.00444209 91.1306 0.763919C92.8679 1.5234 94.3528 2.80955 95.3976 4.45969C96.4423 6.10982 96.9999 8.04986 96.9999 10.0345C96.9971 12.6948 95.9944 15.2453 94.2119 17.1265C92.4294 19.0076 90.0127 20.0657 87.4919 20.0687ZM87.4919 3.35594C86.2403 3.35594 85.0169 3.7476 83.9762 4.48144C82.9355 5.21529 82.1244 6.25835 81.6455 7.47868C81.1665 8.69902 81.0412 10.0419 81.2854 11.3374C81.5295 12.6329 82.1323 13.8228 83.0173 14.7568C83.9023 15.6908 85.0298 16.3269 86.2573 16.5846C87.4849 16.8423 88.7573 16.71 89.9136 16.2046C91.0699 15.6991 92.0583 14.8431 92.7536 13.7448C93.449 12.6465 93.8201 11.3553 93.8201 10.0345C93.8182 8.26382 93.1509 6.56625 91.9645 5.31423C90.7782 4.0622 89.1697 3.35795 87.4919 3.35594Z"
              fill="white"
            />
          </svg>

          <a
            href="https://www.xixoio.com/kontakt"
            className="block font-semibold"
          >
            {i18n.t("nav.customer_support")}
          </a>

          <a
            href="https://www.xixoio.com/dokumenty"
            className="block font-semibold"
          >
            {i18n.t("nav.documents")}
          </a>

          <a
            href="https://xixdocuments.s3.eu-central-1.amazonaws.com/Pravidla+zpracova%CC%81ni%CC%81+osobni%CC%81ch+u%CC%81daju%CC%8A.pdf"
            className="block font-semibold"
          >
            {i18n.t("nav.personal_data_processing")}
          </a>

          <a
            href="https://xixdocuments.s3.eu-central-1.amazonaws.com/Vs%CC%8Ceobecne%CC%81+obchodni%CC%81+podmi%CC%81nky.pdf"
            className="block font-semibold"
          >
            {i18n.t("nav.general_terms_and_conditions")}
          </a>

          <span className="block text-sm text-white-100 text-opacity-75">
            © {new Date().getFullYear()}, XIXOIO, LTD.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
