import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import CardTitle from "./CardTitle.jsx";

const Card = ({ title, header, children, className, ...props }) => {
  const cls = classNames("card", className);

  return (
    <div className={cls} {...props}>
      {title && <CardTitle>{title}</CardTitle>}
      {header && header}
      {children}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default React.memo(Card);
