import icons from "./icons/selection.json";

export const { prefix } = icons.preferences.fontPref;
export const iconList = icons.icons.map((icon) => icon.properties.name);

export const text = {
  headline: {
    short: "Lorizzle pimpin",
    medium: "Lorizzle pimpin' dolizzle sit amet I",
    long: "Rizzle adipiscing elizzle. Nullam sapien velizzle, shit volutpizzle, my",
  },
  excerpt: {
    short:
      "Shizz fo shizzle mah nizzle fo rizzle, mah home g-dizzle, gravida vizzle, arcu. Pellentesque crunk tortizzle. Sed erizzle. Black izzle sheezy telliv.",
    medium:
      "Izzle crazy tempizzle sizzle. We gonna chung gangsta get down get down fo shizzle turpizzle. Away break it down black. Pellentesque bling bling rhoncus fo shizzle. In hac the bizzle platea dictumst. Black dapibizzle. Crackalackin.",
    long: "Curabitizzle fo shizzle diam quizzle nisi nizzle mollizzle. Suspendisse boofron. Morbi odio. Sure pizzle. Crazy orci. Shut the shizzle up maurizzle get down get down, check out this a, go to hizzle sit amizzle, malesuada izzle, pede. Pellentesque gravida. Vestibulizzle check it out mi, volutpat izzle, shiz sed, shiznit sempizzle, da bomb. Funky fresh in ipsum. Da bomb volutpat felis vizzle daahng dawg. Crizzle quis dope izzle fo shizzle my ni.",
  },
  misc: {
    phone: "+420 777 123 123",
    url: "http://lorizzle.nl/very_looooong_shnizzle_diam_rizzle_gangsta_wicked_izzle/suspendisse_morbi_booyakasha",
    email: "franta.uzivatel@thelongestemailaddressintheworld.email",
  },
};

export const items = [
  { key: "1", value: "Rizzle adipiscing elizzle" },
  { key: "2", value: "Curabitizzle fo shizzle diam quizzle nisi nizzle" },
  { key: "3", value: "Nullam sapien velizzle" },
  { key: "4", value: "Shut the shizzle up maurizzle get down get down" },
  { key: "5", value: "Suspendisse boofron" },
  { key: "6", value: "Vestibulizzle check it out mi, volutpat" },
];

export const person = {
  "first": "Junius",
  "middle": "Marius",
  "last": "Koolen",
  "full": "Junius Marius Koolen",
  "occupation": "Future Directives Administrator",
  "age": "30",
  "label": "Obchodní zástupce"
};
