import React, { useState } from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import { useCombobox } from "downshift";
import PropTypes from "prop-types";

import { Icon } from "../Icon";
import { Spinner } from "../Spinner";

const SINGLE_COLUMN_LENGTH = 6;

const defaultRenderItemValue = (item, isSelected) => <span>{item.value}</span>;

const Combobox = (
  {
    className,
    controlClassGeneratorFn = null,
    items = [],
    loading = false,
    placeholder = "Vyberte",
    onQuery,
    onFocus = () => {},
    onToggle,
    onChange,
    highlightFirstItem = false,
    clearAfterSelection = false,
    menuAlignment = "left",
    renderMenuItem = defaultRenderItemValue,
    icon,
  },
  ref
) => {
  const [inputValue, setInputValue] = useState("");

  const updateInputValue = (val) => {
    if (val !== inputValue) {
      setInputValue(val);
      !!onQuery && onQuery(val);
    }
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useCombobox({
    inputValue,
    initialHighlightedIndex: highlightFirstItem ? 0 : null, // after selection, highlight the first item.
    items,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          updateInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          updateInputValue("");
          openMenu();
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            onChange(selectedItem);
            updateInputValue(clearAfterSelection ? "" : selectedItem.value);
          }
          break;
        default:
          break;
      }
    },
  });

  const inputClass = classNames("text-input");
  const ulClass = classNames(
    "combobox__list bg-gray-700 absolute shadow-lg z-50 rounded-large max-w-full lg:max-w-none",
    {
      "lg:grid lg:grid-rows-6 lg:grid-flow-col lg:auto-cols-max":
        items.length >= SINGLE_COLUMN_LENGTH,
      "hidden lg:hidden": !isOpen,
      "left-0": menuAlignment === "left",
      "right-0": menuAlignment === "right",
    }
  );

  const spinnerClass = classNames(
    "w-8 h-4 self-center transition-opacity duration-200",
    {
      hidden: !loading,
      block: loading,
    }
  );

  const toggleClass = classNames("self-center", {
    hidden: loading,
  });

  const itemBaseClass =
    "flex flex-shrink-0 p-3 whitespace-nowrap w-full text-left border-b border-gray-700 border-opacity-50 cursor-pointer";

  return (
    <>
      <label className={className} ref={ref}>
        <div {...getComboboxProps()} className="relative flex flex-grow">
          <div className="combobox__input">
            <input
              {...getInputProps({
                ref,
                onFocus: () => {
                  if (!isOpen) {
                    openMenu();
                  }
                  onFocus(true);
                },
              })}
              className={inputClass}
              type="search"
              placeholder={placeholder}
            />
          </div>
          <div
            data-testid="Global search toggle"
            className="combobox__toggle"
            onClick={() => (onToggle ? onToggle() : null)}
          >
            <Icon icon={icon} className={toggleClass} />
            <Spinner className={spinnerClass} />
          </div>
        </div>
      </label>

      <div className="combobox__dropdown">
        <div className="w-full">
          <ul
            className={ulClass}
            aria-label="search-results"
            {...getMenuProps()}
          >
            {isOpen && !loading && items.length === 0 && (
              <li className={classNames(itemBaseClass, "text-xs")}>
                Žádné odpovídající záznamy.
              </li>
            )}
            {isOpen &&
              items.length > 0 &&
              items.map((item, index) => {
                const isSelected = highlightedIndex === index;
                return (
                  <li
                    key={`${item.key}_${index}`}
                    {...getItemProps({ item, index })}
                    className={itemBaseClass}
                  >
                    {renderMenuItem(item, isSelected)}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export const ComboboxInput = React.forwardRef(Combobox);

export const ComboboxControl = React.forwardRef(
  ({ formControl: control, name, ...props }, ref) => {
    return (
      <Controller
        control={control}
        name={name}
        render={({ onChange, onBlur, value }) => (
          <ComboboxInput
            {...props}
            ref={ref}
            selectedItems={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
    );
  }
);

const ItemShape = PropTypes.shape({
  key: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
});

const BaseProps = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  controlClassGeneratorFn: PropTypes.func,
  items: PropTypes.arrayOf(ItemShape).isRequired,
  onQuery: PropTypes.func,
  onToggle: PropTypes.func,
  openOnFocus: PropTypes.bool,
  renderMenuItem: PropTypes.func,
  highlightFirstItem: PropTypes.bool,
  clearAfterSelection: PropTypes.bool,
  loading: PropTypes.bool,
  menuAlignment: PropTypes.oneOf(["left", "right"]),
  icon: PropTypes.string,
};

ComboboxInput.propTypes = {
  ...BaseProps,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

ComboboxControl.propTypes = {
  ...BaseProps,
  formControl: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
