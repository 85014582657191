import React, { Suspense, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { GET_RATE } from "queries/buying";
import { useModal } from "utils/hooks";

import PaymentDetailModal from "./PaymentDetailModal";

const BuyRouteProxy = React.lazy(() =>
  import(
    "components/buying/BuyRouteProxy" /* webpackChunkName: "BuyRouteProxy" */
  )
);

const BuyModal = ({ token, currency, min, max, tokensLeftFiat, onClose }) => {
  const { data } = useQuery(GET_RATE, {
    variables: {
      currency: "CZK",
      token,
    },
  });

  const showPaymentDetail = useModal(PaymentDetailModal);
  const navigate = useNavigate();
  const location = useLocation();

  const onBuyScreenChange = useCallback(
    (screen) => {
      const params = new URLSearchParams(location.search);
      params.set("screen", screen);
      navigate(`${location.pathname}?${params.toString()}`);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [navigate, location.pathname]
  );

  max = parseFloat(max) || 0;
  tokensLeftFiat = parseFloat(tokensLeftFiat) || 0;
  const maxOrderLimit = Math.min(max, tokensLeftFiat);

  if (!data) {
    return <></>;
  }

  return (
    <Suspense fallback={<></>}>
      <BuyRouteProxy
        routeParams={{
          token,
          currency,
          min: min * (1 + data.transactionPurchaseFeePercent / 100),
          max: maxOrderLimit * (1 + data.transactionPurchaseFeePercent / 100),
        }}
        onClose={onClose}
        onTransactionComplete={showPaymentDetail}
        onBuyScreenChange={onBuyScreenChange}
        onPayOther={showPaymentDetail}
      />
    </Suspense>
  );
};

BuyModal.path = "/buy/:token/:currency/:min/:max/:tokensLeftFiat";

BuyModal.composePath = (token, currency, min, max, tokensLeftFiat) =>
  `buy/${token}/${currency}/${min}/${max}/${tokensLeftFiat}`;

BuyModal.propTypes = {
  token: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  tokensLeftFiat: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BuyModal;
