import React from "react";
import { Notification } from "@xixoio/components/Notifications";
import i18n from "i18n";

const SellerAgreementSignaturePendingNotification = () => {
  return (
    <Notification warning icon="warning">
      {i18n.t("notifications.pending_signature")}
    </Notification>
  );
};

export default SellerAgreementSignaturePendingNotification;
