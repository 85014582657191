import React, { Suspense } from "react";
import { Card, CardBody, CardTitle } from "@xixoio/components/Card";
import i18n from "i18n";
import PropTypes from "prop-types";

const Modal = React.lazy(() => import("@xixoio/components/Modal/Modal.jsx"));

const showPhishingNotificationModal = ({ onClose }) => {
  return (
    <Suspense fallback={<></>}>
      <Modal
        isOpen
        onRequestClose={onClose}
        containerClassName="max-w-md w-full"
      >
        <Card>
          {
            <>
              <CardTitle onClose={onClose}>
                {i18n.t("phishing.title")}
              </CardTitle>
              <CardBody>
                {i18n.t("phishing.text_1")}
                <br />
                <br />
                {i18n.t("phishing.text_2")}
                <br />
                <br />
                {i18n.t("phishing.text_3")}
                <ol>
                  <li>{i18n.t("phishing.text_4")}</li>
                  <li>{i18n.t("phishing.text_5")}</li>
                  <li>{i18n.t("phishing.text_6")}</li>
                  <li>{i18n.t("phishing.text_7")}</li>
                  <li>{i18n.t("phishing.text_8")}</li>
                  <li>{i18n.t("phishing.text_9")}</li>
                  <li>{i18n.t("phishing.text_10")}</li>
                </ol>
                <br />
                <br />
                {i18n.t("phishing.text_11")}
              </CardBody>
            </>
          }
        </Card>
      </Modal>
    </Suspense>
  );
};

showPhishingNotificationModal.path = "/phishing-notification";

showPhishingNotificationModal.composePath = () => `phishing-notification`;

showPhishingNotificationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default showPhishingNotificationModal;
