import { useState } from "react";

const getCurrentTimestamp = () =>
  Math.round(new Date(Date.now()).getTime() / 1000);

export const useTimedHide = (
  localStorageKey,
  hideFor = { days: 14, hours: 0, minutes: 0 }
) => {
  const [hiddenUntil, setHiddenUntil] = useState(
    parseInt(localStorage.getItem(localStorageKey) || 0)
  );
  const isHidden = getCurrentTimestamp() < hiddenUntil;

  const hide = () => {
    const hideUntil =
      getCurrentTimestamp() +
      24 * 60 * 60 * (hideFor.days || 0) +
      60 * 60 * (hideFor.hours || 0) +
      60 * (hideFor.minutes || 0);

    localStorage.setItem(localStorageKey, hideUntil);
    setHiddenUntil(hideUntil);
  };

  return [isHidden, hide];
};
