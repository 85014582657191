import React, { useCallback } from "react";
import { Button } from "@xixoio/components/Button";
import { Notification } from "@xixoio/components/Notifications";
import i18n from "i18n";

import PaymentDetailModal from "modals/PaymentDetailModal";
import { useModal } from "utils/hooks";

const PendingTransactionsNotification = ({ transactions }) => {
  const showPaymentDetail = useModal(PaymentDetailModal);

  const showPaymentInstructions = useCallback(
    (transaction) => () => {
      showPaymentDetail(transaction);
    },
    [showPaymentDetail]
  );

  return (
    <Notification
      icon="time"
      cta={
        transactions.length === 1 && (
          <Button
            onClick={showPaymentInstructions(transactions[0])}
            secondary
            textCenter
            small
            className="whitespace-nowrap"
          >
            {i18n.t("notifications.pending_transactions.text_1")}
          </Button>
        )
      }
    >
      {transactions.length === 1 && (
        <>
          {i18n.t("notifications.pending_transactions.text_2", {
            order_number: transactions[0].payment.referenceNumber,
          })}
        </>
      )}
      {transactions.length > 1 && (
        <>
          {i18n.t("notifications.pending_transactions.text_3")}{" "}
          {transactions.map((transaction, idx) => (
            <React.Fragment key={transaction.id}>
              <button
                className="underline"
                onClick={showPaymentInstructions(transaction)}
              >
                {transaction.payment.referenceNumber}
              </button>
              {idx !== transactions.length - 1 && ", "}
            </React.Fragment>
          ))}
          {"."}
        </>
      )}
    </Notification>
  );
};

export default PendingTransactionsNotification;
