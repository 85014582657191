import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import { from, split } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { RestLink } from "apollo-link-rest";
import { createUploadLink } from "apollo-upload-client";
import * as camelCase from "camelcase";
import keycloak from "keycloak";
import * as snake_case from "snake-case";

import {
  impersonatorMutationGuardLink,
  setHeadersLink,
  storeImpersonatorLink,
} from "./links";
import possibleTypes from "./possible-types.json";

const SORRY_PAGE_URL = "https://moje.xixoio.com/503/index.html";

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError && networkError.statusCode === 503) {
    window.location.replace(SORRY_PAGE_URL);
  }
});

const REACT_APP_CMS_URL =
  process.env.REACT_APP_CMS_URL ||
  "https://api-eu-central-1.graphcms.com/v2/ckmdkr8g7859001xgatv71ydf/master";

const REACT_APP_MERK_URL =
  process.env.REACT_APP_MERK_URL ||
  "http://api-gateway.127-0-0-1.nip.io:9090/merk/v1";

const REACT_APP_XIXOUT_URL =
  process.env.REACT_APP_XIXOUT_URL || "https://test.xixout.com/wp-json/";

const restLink = new RestLink({
  uri: "",
  endpoints: {
    merk: REACT_APP_MERK_URL,
  },
  fieldNameNormalizer: (key) => camelCase(key),
  fieldNameDenormalizer: (key) => snake_case(key),
});

const xixRestLink = new RestLink({
  uri: "",
  endpoints: {
    xixout: REACT_APP_XIXOUT_URL,
  },
  customFetch: (req, init) =>
    fetch(req, {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    }),
  fieldNameNormalizer: (key) => camelCase(key),
  fieldNameDenormalizer: (key) => snake_case(key),
});

const cmsLink = new HttpLink({
  uri: REACT_APP_CMS_URL,
});

// Helper for `relayStylePagination` to make listed `keyArgs` optional.
// Otherwise all the arguments have be used by all the queries.
// @see: https://github.com/apollographql/apollo-client/issues/6973
const filterKeyArgsToArgs = (keyArgs) => (args) =>
  args ? keyArgs.filter((keyArg) => args.hasOwnProperty(keyArg)) : null;

const clientFields = {
  clients: relayStylePagination(
    filterKeyArgsToArgs([
      "includingDeleted",
      "includingSelf",
      "levelSpan",
      "kind",
      "query",
      "clientIds",
    ])
  ),
  transactions: relayStylePagination(filterKeyArgsToArgs(["state", "type"])),
  commissions: relayStylePagination(),
  operations: relayStylePagination(
    filterKeyArgsToArgs([
      "period",
      "listType",
      "withNested",
      "clientIds",
      "token",
    ])
  ),
};

export const buildCache = (props) => {
  // Set up our apollo-client to point at the server we created
  // this can be local or a remote endpoint
  const cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
      MeType: {
        // MeType is singleton, does not need key-based caching.
        keyFields: [],
        fields: clientFields,
      },
      NetworkRootType: {
        // NetworkRootType is singleton, does not need key-based caching.
        keyFields: [],
        fields: {
          clients: clientFields.clients,
        },
      },
      MonthlyCommissionType: {
        keyFields: ["month"],
      },
      ClientType: {
        fields: clientFields,
      },
      // upgrade to 3.5.X when fixed: https://github.com/apollographql/apollo-client/issues/9333, https://github.com/apollographql/apollo-client/issues/9203
      TokenType: {
        keyFields: ["name"],
        fields: {
          valueHistory: relayStylePagination(
            filterKeyArgsToArgs(["datetimeFrom", "datetimeUntil"])
          ),
        },
      },
      ProjectType: {
        keyFields: ["title"],
      },
    },
    ...props,
  });

  return cache;
};

const composeServiceLinkUrl = (serviceName) => {
  const BASE_URL =
    process.env.REACT_APP_GRAPHQL_BASE_URI || "http://localhost:9999/graphql";
  return `${BASE_URL}/${serviceName}`;
};

const createServiceUploadLink = (serviceName) => {
  const serviceLink = composeServiceLinkUrl(serviceName);
  return createUploadLink({ uri: `${serviceLink}/graphql` });
};

//https://test.xixout.com/wp-json/xixoio/v1/notification/list/

const createBatchUploadLink = (serviceName) => {
  const serviceLink = composeServiceLinkUrl(serviceName);
  return new BatchHttpLink({ uri: `${serviceLink}/graphql-batch` });
};

const createServiceLink = (serviceName) => {
  const uploadLink = createServiceUploadLink(serviceName);
  let baseLink = uploadLink;

  if (!process.env.REACT_APP_DISABLE_QUERY_BATCHING) {
    const isFile = (value) =>
      (typeof File !== "undefined" && value instanceof File) ||
      (typeof Blob !== "undefined" && value instanceof Blob);

    const isUpload = ({ variables }) => Object.values(variables).some(isFile);
    // Both upload and batch are terminal links. Hence we need to decide which one
    // to use in given case.
    // @see: https://github.com/jaydenseric/apollo-upload-client/issues/63
    const requestLink = createBatchUploadLink(serviceName);
    baseLink = split(isUpload, uploadLink, requestLink);
  }
  return baseLink;
};

export const createSplitLink = (serviceName, defaultServiceName) => {
  return split(
    ({ getContext }) => getContext().backend === serviceName,
    createServiceLink(serviceName),
    createServiceLink(defaultServiceName)
  );
};

const restLinks = split(
  ({ getContext }) => getContext().backend === "xixout",
  xixRestLink,
  restLink
);

export const createApolloClient = () => {
  const xixBackendLink = from([
    errorLink,
    setHeadersLink,
    storeImpersonatorLink,
    impersonatorMutationGuardLink,
    restLinks,
    createSplitLink("aml", "myx"),
  ]);

  return new ApolloClient({
    cache: buildCache(),
    link: split(
      ({ getContext }) => getContext().backend === "cms",
      cmsLink,
      xixBackendLink
    ),
  });
};
