import React from "react";
import { Button } from "@xixoio/components/Button";
import { Notification } from "@xixoio/components/Notifications";
import i18n from "i18n";

import { PhishingNotificationModal } from "modals";
import { useModal } from "utils/hooks";

const PhishingNotification = () => {
  const showPhishingNotificationModal = useModal(PhishingNotificationModal);

  return (
    <Notification
      icon="warning"
      cta={
        <Button
          onClick={() => showPhishingNotificationModal()}
          secondary
          small
          textCenter
          className="whitespace-nowrap"
        >
          {i18n.t("more_informations")}
        </Button>
      }
    >
      <strong>{i18n.t("notifications.phishing")}</strong>
    </Notification>
  );
};

export default PhishingNotification;
