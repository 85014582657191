export const TransactionState = Object.freeze({
  PENDING: "PENDING",
  DONE: "DONE",
  CANCELLED: "CANCELLED",
});

export const BlockchainTransactionState = Object.freeze({
  NOT_DONE: "NOT_DONE",
  INITIATED: "INITIATED",
  PENDING: "PENDING",
  WRITTEN: "WRITTEN",
  ERROR: "ERROR",
  UNKNOWN: "UNKNOWN",
});

export const TransactionType = Object.freeze({
  MINT_CRYPTO_TO_POOL: "MINT_CRYPTO_TO_POOL",
  INWARD_PAYMENT: "INWARD_PAYMENT",
  OUTWARD_LOCAL: "OUTWARD_LOCAL",
  REFERRAL_FEE: "REFERRAL_FEE",
  SHARES: "SHARES",
  REFERRAL_BONUS: "REFERRAL_BONUS",
  BONUS: "BONUS",
});

export const Role = Object.freeze({
  SELLER: "seller",
  MASTER_SELLER: "master-seller",
});

export const CommissionPayoutState = Object.freeze({
  NOT_DONE: "NOT_DONE",
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  UNPROCESSABLE: "UNPROCESSABLE",
});

export const EmploymentSector = Object.freeze({
  EDUCATION: "EDUCATION",
  ARMED_FORCES: "ARMED_FORCES",
  HEALTHCARE: "HEALTHCARE",
  TRANSPORT: "TRANSPORT",
  PUBLIC_ADMINISTRATION: "PUBLIC_ADMINISTRATION",
  FINANCE_INSURANCE: "FINANCE_INSURANCE",
  TELECOMMUNICATIONS: "TELECOMMUNICATIONS",
  ENERGY: "ENERGY",
  ITC: "ITC",
  INDUSTRIAL: "INDUSTRIAL",
  BUSINESS: "BUSINESS",
  AGRICULTURAL: "AGRICULTURAL",
});

export const FundingSource = Object.freeze({
  EMPLOYED: "EMPLOYED",
  SELF_EMPLOYED: "SELF_EMPLOYED",
  RENTALS: "RENTALS",
  PROPERTY_SETTLEMENT: "PROPERTY_SETTLEMENT",
  CAPITAL_ASSETS: "CAPITAL_ASSETS",
  OTHER_INCOME: "OTHER_INCOME",
});
