export const getCurrentBreakpointName = () => {
  try {
    // Assumes <body class="embed-screen">
    const breakpointStyle = window.getComputedStyle(document.body, "::after");
    return breakpointStyle.content.replace(/"/g, "");
  } catch (err) {
    // In tests, the above will probably throw as jsdom doesn't support
    // getComputedStyle on pseudoelements.
    return "";
  }
};
