import React from "react";
import { Role } from "enums";
import PropTypes from "prop-types";

import { IfNotRole, IfRole } from "./IfRole";

export const IfMasterSeller = ({ children }) => {
  return <IfRole role={Role.MASTER_SELLER}>{children}</IfRole>;
};

export const IfNotMasterSeller = ({ children }) => {
  return <IfNotRole role={Role.MASTER_SELLER}>{children}</IfNotRole>;
};

IfMasterSeller.propTypes = IfNotMasterSeller.propTypes = {
  children: PropTypes.node,
};

export default IfMasterSeller;
