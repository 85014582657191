import React from "react";
import { Button } from "@xixoio/components/Button";
import { Notification } from "@xixoio/components/Notifications";
import i18n from "i18n";

const SellerDetailsMissingNotification = ({ contractFormUrl }) => {
  return (
    <Notification
      warning
      icon="warning"
      cta={
        <Button
          routerTo={contractFormUrl}
          small
          secondary
          textCenter
          className="whitespace-nowrap"
        >
          {i18n.t("notifications.missing_seller_details.text_1")}
        </Button>
      }
    >
      {i18n.t("notifications.missing_seller_details.text_2")}
    </Notification>
  );
};

export default SellerDetailsMissingNotification;
