import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { GET_ME } from "queries/user";

/**
 * A route guard that will only allow route access if all user tests specified
 * in `test` input argument pass.
 */
const TestProtectedRoute = ({ tests, fallbackTo = "/permission-denied" }) => {
  const { loading, data } = useQuery(GET_ME);

  if (loading) return <></>;

  const isEligible = tests.every((fn) => fn(data.me));

  return (
    <React.Suspense fallback={<>...</>}>
      {isEligible ? <Outlet /> : <Navigate to={fallbackTo} />}
    </React.Suspense>
  );
};

TestProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
  tests: PropTypes.arrayOf(PropTypes.func).isRequired,
  fallbackTo: PropTypes.string,
};

export default TestProtectedRoute;
