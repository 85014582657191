import React from "react";
import { Button } from "@xixoio/components/Button";
import { Card, CardBody } from "@xixoio/components/Card";
import i18n from "i18n";

import { useHistoryCallback } from "utils/hooks";

const ErrorCard = ({ title, text }) => {
  const goHome = useHistoryCallback((history) => {
    history("/");
  });

  return (
    <Card className="max-w-lg m-auto my-8 md:my-20 lg:p-8">
      <CardBody>
        <h2 className="text-xl font-semibold text-red-400 mt-4 mb-2">
          {title}
        </h2>
        <p className="text-lg">{text}</p>
        <div className="text-center mt-12">
          <Button primary onClick={goHome}>
            {i18n.t("error_card.back_to_overview")}
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default ErrorCard;
