import React from "react";
import classNames from "classnames";

const CardIllustration = ({ children, className, ...props }) => {
  const cls = classNames("card__illustration block", className);
  const Tag = props.href ? "a" : "div";

  return (
    <Tag className={cls} {...props}>
      {children}
    </Tag>
  );
};

export default React.memo(CardIllustration);
