import React, { useEffect, useState } from "react";
import { BrowserView } from "react-device-detect";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import ReactHintFactory from "react-hint";
import ReactModal from "react-modal";
import { ModalProvider } from "react-modal-hook";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ApolloProvider, useQuery } from "@apollo/client";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SpinnerContainer } from "@xixoio/components/Spinner";
import { BreakpointProvider } from "@xixoio/responsive";
import classNames from "classnames";
import { Role } from "enums";

import { TestProtectedRoute, validators } from "components/auth";
import Footer from "components/nav/Footer";
import Navbar from "containers/nav/Navbar";
import { ALL_MODALS, ModalRenderer } from "modals";
import NotFoundPage from "pages/NotFound";
import PermissionDeniedPage from "pages/PermissionDenied";
import { dataLayer } from "utils/gtm";

import "./i18n";
import "unfocus/dist/unfocus.js";

import { GET_ME } from "./queries/user";
import { createApolloClient } from "./apollo";
import keycloak from "./keycloak";

import "react-hint/css/index.css";
import "slick-carousel/slick/slick.css";

/* Dynamically loaded pages to speed up time to first render */
const ClientsPage = React.lazy(() =>
  import("pages/Clients" /* webpackChunkName: "ClientsPage" */)
);
const CommissionPage = React.lazy(() =>
  import("pages/Commission" /* webpackChunkName: "CommissionPage" */)
);
const DashboardPage = React.lazy(() =>
  import("pages/Dashboard" /* webpackChunkName: "DashboardPage" */)
);
const IdentityVerificationPage = React.lazy(() =>
  import(
    "pages/IdentityVerification" /* webpackChunkName: "IdentityVerificationPage" */
  )
);
const SellerContactForm = React.lazy(() =>
  import(
    "pages/SellerContractForm" /* webpackChunkName: "IdentityVerificationPage" */
  )
);
const FinancialOperationsOverviewPage = React.lazy(() =>
  import(
    "pages/FinancialOperationsOverview" /* webpackChunkName: "FinancialOperationsOverviewPage" */
  )
);
const PortfolioPage = React.lazy(() =>
  import("pages/Portfolio" /* webpackChunkName: "PortfolioPage" */)
);
const SellerDetailPage = React.lazy(() =>
  import("pages/SellerDetail" /* webpackChunkName: "SellerDetailPage" */)
);
const ClientDetailPage = React.lazy(() =>
  import("pages/ClientDetail" /* webpackChunkName: "ClientDetailPage" */)
);
const MasterSellerOverviewPage = React.lazy(() =>
  import(
    "pages/MasterSellerOverview" /* webpackChunkName: "MasterSellerOverviewPage" */
  )
);

/**
 * If configured, set up Sentry client that reports uncaught errors down to
 * https://sentry.io.
 */
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    release: process.env.REACT_APP_SENTRY_RELEASE,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          new URL(process.env.REACT_APP_MERK_URL).hostname,
          new URL(process.env.REACT_APP_GRAPHQL_BASE_URI).hostname,
          new URL(process.env.REACT_APP_GRAPHQL_BATCH_URI).hostname,
        ],
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

if (!!process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

/**
 * Initialize Product Fruits service: https://productfruits.com/
 * @param meData authenticated user data
 * @returns {boolean} true if initialization was made, false otherwise
 */
const initializeProductFruits = (meData) => {
  /**
   * For now we want only role === "seller" to see PF, therefore we initialize PF only for those.
   * It is also matter of money as PF plans are based on number of active users.
   *
   * If you change this condition, take in account that also Tours and other PF features should be edited
   * in terms of theirs "visibility rules" - e.g. PF features visible only to sellers:
   *    custom rule: User's role | contains | "seller"
   *
   * We do not have access to PF admin, therefore we should notify responsible XIXOIO person about this
   */
  if (!meData.me.iamRoles.includes("seller")) {
    return false;
  }

  try {
    window.productFruitsUser = {
      username: meData.me.id,
      email: meData.me.email,
      role: meData.me.iamRoles.join(" "),
    };

    window.productFruits = window.productFruits || {};
    window.productFruits.language = "cs";
    window.productFruits.code = process.env.REACT_APP_PRODUCT_FRUITS_PROJECT_ID;
    const a = document.getElementsByTagName("head")[0];
    const r = document.createElement("script");
    r.async = true;
    r.src = `https://app.productfruits.com/static/script.js?c=${process.env.REACT_APP_PRODUCT_FRUITS_PROJECT_ID}`;
    a.appendChild(r);
  } catch (e) {
    console.error(e);
    return false;
  }

  return true;
};

const ReactHint = ReactHintFactory(React);

const InitializedApp = () => {
  const { data: meData } = useQuery(GET_ME);

  const [productFruitsInitialised, setProductFruitsInitialised] =
    useState(false);

  useEffect(() => {
    if (meData && !productFruitsInitialised) {
      setProductFruitsInitialised(initializeProductFruits(meData));
    }
  }, [meData, productFruitsInitialised, setProductFruitsInitialised]);

  return (
    <div
      className={classNames("flex flex-col min-h-screen", {
        "debug-screens": process.env.REACT_APP_DEBUG === "1",
      })}
    >
      <Navbar />
      <div className="flex-grow py-16 lg:py-20 mt-5 lg:mt-10 bg-gray-900">
        <div className="container">
          <Routes>
            <Route
              path="/"
              element={
                <React.Suspense fallback={<>...</>}>
                  <DashboardPage />
                </React.Suspense>
              }
            />
            {/* Display portfolio page (even if /portfolio/transaction/[id] is what's actually hit) */}
            <Route
              path="/portfolio"
              element={
                <React.Suspense fallback={<>...</>}>
                  <PortfolioPage />
                </React.Suspense>
              }
            />
            <Route
              path="/identity-verification"
              element={
                <React.Suspense fallback={<>...</>}>
                  <IdentityVerificationPage />
                </React.Suspense>
              }
            >
              <Route
                path="/identity-verification/:step"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <IdentityVerificationPage />
                  </React.Suspense>
                }
              />
              <Route
                path="/identity-verification/:identityId/:step"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <IdentityVerificationPage />
                  </React.Suspense>
                }
              />
            </Route>
            <Route
              element={
                <TestProtectedRoute
                  tests={[
                    // Verify that seller is in the appropriate lifecycle stage
                    (me) =>
                      ["SELLER_DETAILS_PENDING", "SIGNATURE_PENDING"].indexOf(
                        me.sellerAgreementState
                      ) !== -1,
                  ]}
                  fallbackTo="/not-found"
                />
              }
            >
              <Route
                path="/seller-contract-form/*"
                element={<SellerContactForm />}
              ></Route>
            </Route>
            <Route
              element={
                <TestProtectedRoute
                  tests={[
                    validators.hasAnyRole([Role.SELLER, Role.MASTER_SELLER]),
                  ]}
                ></TestProtectedRoute>
              }
            >
              <Route
                path="/client/:clientId/*"
                element={<ClientDetailPage />}
              />
              <Route path="/clients/*" element={<ClientsPage />} />
              <Route path="/sellers" element={<SellerDetailPage />} />
              <Route path="/sellers/:clientId" element={<SellerDetailPage />} />
              <Route path="/commission" element={<CommissionPage />} />
              <Route
                path="/operations"
                element={<FinancialOperationsOverviewPage />}
              />
            </Route>

            <Route
              element={
                <TestProtectedRoute
                  tests={[validators.hasRole(Role.MASTER_SELLER)]}
                ></TestProtectedRoute>
              }
            >
              <Route
                path="/master-seller/overview"
                element={<MasterSellerOverviewPage />}
              ></Route>
            </Route>
            <Route
              exact
              path="/permission-denied"
              element={<PermissionDeniedPage />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          {/* Checks the query part of the URL to determine what modal dialog
          should be displayed as an overlay on the current page */}
          <ModalRenderer modals={ALL_MODALS} />
          <BrowserView>
            <ReactHint autoPosition events attribute="data-tooltip" />
          </BrowserView>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const AuthenticatedApp = () => {
  useEffect(() => {
    dataLayer();
  });

  const client = createApolloClient();

  return (
    <Router>
      <ApolloProvider client={client}>
        <BreakpointProvider>
          <ModalProvider>
            <InitializedApp />
          </ModalProvider>
        </BreakpointProvider>
      </ApolloProvider>
    </Router>
  );
};

const ErrorBoundaryFallback = () => (
  <div className="h-screen w-screen flex justify-center items-center">
    <div className="text-center">
      <h1 className="text-3xl mb-4">V aplikaci došlo k chybě :(</h1>
      <p className="text-lg">
        Naši vývojáři o tom již byli informování a opraví to co nejdříve.
        <br />
        Omlouváme se za tuto nepříjemnost.
      </p>
      <a href="/" className="btn btn--primary mt-8">
        <span className="btn__label">Načíst znovu</span>
      </a>
    </div>
  </div>
);

const LoadingComponent = (
  <SpinnerContainer className="h-screen flex-col">
    <p className="block mt-4 font-semibold">Načítání ...</p>
  </SpinnerContainer>
);

const onKeycloakEvent = (event) => {
  if (event === "onTokenExpired") {
    refreshAccessToken();
  } else if (["onAuthRefreshSuccess", "onAuthSuccess"].includes(event)) {
    Sentry.setUser(keycloak.tokenParsed);
  }
};

export const refreshAccessToken = async () => {
  if (!keycloak.authenticated) return;
  try {
    await keycloak.updateToken(60);
  } catch (exc) {
    console.warn("[auth] could not refresh expired token");
    Sentry.setUser(null);
  }
};

const App = Sentry.withProfiler(() => {
  const keycloakInitConfig = {
    onLoad: "login-required",
    // Necessary to prevent Keycloak cookie issues:
    // @see: https://stackoverflow.com/a/63588334/303184
    checkLoginIframe: !(
      process.env.REACT_APP_BYPASS_CHECK_LOGIN_IFRAME === "1"
    ),
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakInitConfig}
        LoadingComponent={LoadingComponent}
        onEvent={onKeycloakEvent}
        autoRefreshToken={false}
      >
        <AuthenticatedApp />
      </ReactKeycloakProvider>
    </Sentry.ErrorBoundary>
  );
});

document.addEventListener(
  "visibilitychange",
  () => {
    if (!document.hidden) {
      refreshAccessToken();
    }
  },
  false
);

const root = document.getElementById("root");

ReactDOM.render(<App />, root);
ReactModal.setAppElement(root);
