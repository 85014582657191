import React from "react";
import classNames from "classnames";

import Spinner from "./Spinner";

const SpinnerContainer = ({ className, spinnerClassName, children }) => (
  <div className={classNames("flex items-center justify-center", className)}>
    <Spinner className={classNames(spinnerClassName)} />
    {children}
  </div>
);

export default React.memo(SpinnerContainer);
