import gql from "graphql-tag";

export const GET_ME = gql`
  query GetMe {
    me {
      id
      avatarImage(transformation: "w_400,h_400,c_fill/w_100")
      fullName
      verboseName
      email
      selfReferral
      phone
      amlExpiresAt
      amlState
      phone
      isPoliticallyExposed
      fundingSource
      employmentSector
      verificationStatus
      iamRoles
      canReferOtherClients
      isSeller
      wallet {
        address
        explorerLink
      }
      sellerAgreementSignedOn
      sellerAgreementState
      parent {
        id
        fullName
        verboseName
        email
        phone
        avatarImage(transformation: "w_400,h_400,c_fill/w_100")
        sellerBadge {
          identifier
          label
        }
      }
      commission {
        fiatFraction
        tokenFraction
        ... on ClientCommissionType {
          fiatCurrency
          fiatSalesNeededForNextLevel
          unmetConditionsForNextLevel
          badge {
            identifier
            label
          }
        }
      }
      impersonatedBy {
        id
        firstName
        lastName
        email
        accessLevel
      }
    }
  }
`;

export const SET_FUNDING_SOURCE = gql`
  mutation setFundingSource(
    $identityId: ID
    $fundingSource: FundingSource
    $employmentSector: EmploymentSector
  ) {
    updateClient(
      identityId: $identityId
      input: {
        fundingSource: {
          fundingSource: $fundingSource
          employmentSector: $employmentSector
        }
      }
    ) {
      client {
        id
        verificationStatus
        amlState
        fundingSource
        employmentSector
        ... on MeType {
          sellerAgreementState
        }
      }
    }
  }
`;

export const GET_MY_CLIENT = gql`
  query GetMyClient($id: ID!) {
    me {
      client(id: $id) {
        id
        identityId
        verboseName
        email
        amlState
        verificationStatus
        phone
        isPoliticallyExposed
        fundingSource
        employmentSector
      }
    }
  }
`;

export const GET_MY_CLIENT_BY_IDENTITY_ID = gql`
  query GetMyClient($identityId: ID!) {
    me {
      client(identityId: $identityId) {
        id
        identityId
        verboseName
        email
        amlState
        verificationStatus
        phone
        isPoliticallyExposed
        fundingSource
        employmentSector
      }
    }
  }
`;
