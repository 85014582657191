import { useLocation, useNavigate } from "react-router-dom";

import { QUERY_PARAM } from "modals/ModalRenderer";

export const useModal = (modalComponent) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (...args) => {
    const modalPath = modalComponent.composePath(...args);
    const params = new URLSearchParams(location.search);
    params.set(QUERY_PARAM, modalPath);

    navigate({
      search: `?${params.toString()}`,
    });
  };
};
