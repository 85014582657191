import React from "react";
import { Button } from "@xixoio/components/Button";
import { Notification } from "@xixoio/components/Notifications";
import i18n from "i18n";

const OTPNotSetNotification = ({ setupUrl }) => (
  <Notification
    icon="info"
    cta={
      <Button
        href={setupUrl}
        small
        secondary
        textCenter
        className="whitespace-nowrap"
      >
        {i18n.t("notifications.OTP.turn_on")}
      </Button>
    }
  >
    {i18n.t("notifications.OTP.text_1")}
  </Notification>
);

export default OTPNotSetNotification;
