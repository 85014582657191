import React from "react";
import classNames from "classnames";

const Spinner = ({ className }) => (
  <svg className={classNames("spinner", className)} viewBox="25 25 50 50">
    <title>Loading</title>
    <circle
      className="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default React.memo(Spinner);
