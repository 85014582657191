import React, { Suspense } from "react";
import PropTypes from "prop-types";

const Modal = React.lazy(() => import("@xixoio/components/Modal/Modal.jsx"));

const SellerContactCardContainer = React.lazy(() =>
  import(
    "containers/sellers/SellerContactCardContainer" /* webpackChunkName: "SellerContactCardContainer" */
  )
);

const SellerContactModal = ({ onClose }) => {
  return (
    <Suspense fallback={<></>}>
      <Modal
        isOpen
        onRequestClose={onClose}
        containerClassName="max-w-md w-full"
      >
        <SellerContactCardContainer onClose={onClose} />
      </Modal>
    </Suspense>
  );
};

SellerContactModal.path = "/seller-contact";

SellerContactModal.composePath = () => "seller-contact";

SellerContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SellerContactModal;
