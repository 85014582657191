import React from "react";
import classNames from "classnames";

const ErrorMessage = ({ children, className }) => {
  const cls = classNames("inline-block font-semibold text-red-400", className);

  return <div className={cls}>{children}</div>;
};

export default ErrorMessage;
