import { useContext, useMemo } from "react";

import { BreakpointContext } from "./context";

/**
 * Get responsivness state info from current breakpoint name.
 * @return {XIXOIO.responsive.UseBreakpointResult}
 */
export const computeProps = (currentBreakpoint) => {
  const breakpointNames = ["xs", "sm", "md", "lg", "xl", "2xl"];
  const queries = breakpointNames.reduce((acc, breakpoint) => {
    const breakpointNameCapitalized =
      breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1);

    // Accessor for exact match isExactlySm, isExactlyMd, ..
    acc[`isExactly${breakpointNameCapitalized}`] =
      currentBreakpoint === breakpoint;

    // Accessors for at least or higher: isMd, isAboveMd (will match md, lg, xl)
    acc[`is${breakpointNameCapitalized}`] =
      breakpointNames.indexOf(currentBreakpoint) >=
      breakpointNames.indexOf(breakpoint);
    acc[`isAbove${breakpointNameCapitalized}`] =
      acc[`is${breakpointNameCapitalized}`];

    // Accesor for lower than: isBelowLg (will match md and sm)
    acc[`isBelow${breakpointNameCapitalized}`] =
      breakpointNames.indexOf(currentBreakpoint) <
      breakpointNames.indexOf(breakpoint);

    return acc;
  }, {});

  // Aliases for easier access.
  queries["isMobile"] = queries["isBelowMd"];
  queries["isTablet"] = queries["isBelowLg"];
  queries["isDesktop"] = queries["isAboveMd"];

  return { current: currentBreakpoint, ...queries };
};

/**
 * Get current responsivness state reactively.
 */
export const useBreakpoint = () => {
  const currentBreakpoint = useContext(BreakpointContext);
  return useMemo(() => computeProps(currentBreakpoint), [currentBreakpoint]);
};
