import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { ComboboxInput } from "@xixoio/components/FormFields/Combobox.jsx";
import classNames from "classnames";
import i18n from "i18n";
import PropTypes from "prop-types";

import { SEARCH_CLIENTS } from "queries/clients";
import { useDebounce } from "utils/hooks";
import { renderClientSearchMenuItem } from "utils/search/renderers";

const Search = ({ className, opened, onOpen, onClose }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery, { signal }] = useDebounce("");
  const apolloClient = useApolloClient();
  const history = useNavigate();
  const location = useLocation();

  // When search is closed, clear out the query.
  useEffect(() => {
    if (!opened && query !== "") {
      setQuery("");
    }
  }, [opened, query, setQuery]);

  // Update the available items when opened according to current query.
  useEffect(() => {
    let isMounted = true;

    function fetchData() {
      setLoading(true);

      apolloClient
        .query({
          query: SEARCH_CLIENTS,
          variables: {
            includingSelf: true,
            perPage: 10,
            query,
          },
        })
        .then(({ data }) => {
          if (isMounted && data?.me) {
            setItems(
              data.me.clients.edges.map((edge) => ({
                key: edge.node.id,
                value: edge.node.verboseName,
                client: edge.node,
              }))
            );
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
    }

    if (!opened) {
      return;
    }

    fetchData();

    return () => (isMounted = false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [signal, opened]);

  const onToggle = useCallback(() => {
    opened ? onClose() : onOpen();
  });

  const onSelected = useCallback((selection) => {
    const client = selection.client;

    if (client.isSeller) {
      history(
        `/sellers/${client.id}?back=${encodeURIComponent(location.pathname)}`
      );
    } else {
      history(
        `/client/${client.id}?back=${encodeURIComponent(location.pathname)}`
      );
    }

    onClose();
  });

  return (
    <div
      className={classNames(
        "global-search",
        { "global-search--opened": opened },
        className
      )}
    >
      <ComboboxInput
        className={classNames("lg:absolute right-0", {
          "text-green-400": query,
        })}
        controlClassGeneratorFn={() => ""}
        data-testid="Global search input"
        placeholder={i18n.t("navbar.search")}
        items={items}
        onChange={onSelected}
        onQuery={setQuery}
        onToggle={onToggle}
        loading={loading}
        clearAfterSelection={true}
        renderMenuItem={renderClientSearchMenuItem}
        menuAlignment="right"
        icon="magnifier"
      />
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  opened: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default Search;
