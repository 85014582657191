import React, { Suspense } from "react";
import { useQuery } from "@apollo/client";
import { Card, CardBody, CardTitle } from "@xixoio/components/Card";
import i18n from "i18n";
import PropTypes from "prop-types";

import { IfNotSeller, IfSeller } from "components/auth";
import { TOKEN_REF_BONUS } from "queries/tokens";
import { GET_ME } from "queries/user";

const Modal = React.lazy(() => import("@xixoio/components/Modal/Modal.jsx"));
const ReferralCard = React.lazy(() =>
  import("components/nav/ReferralCard" /* webpackChunkName: "ReferralCard" */)
);
const ClientInviteContainer = React.lazy(() =>
  import(
    "containers/clients/ClientInviteContainer" /* webpackChunkName: "ClientInviteContainer" */
  )
);

const InviteUserModal = ({ onClose, inviteStyle, role }) => {
  const { data: tokenData } = useQuery(TOKEN_REF_BONUS, {
    variables: {
      tokenName: "XIX",
    },
  });
  const { data: meData, loading: meLoading, error: meError } = useQuery(GET_ME);
  if (meLoading || meError) {
    return null;
  }
  const referralCode = meData.me.selfReferral;
  const referralLink =
    process.env.REACT_APP_REFERRAL_INVITE_LINK_TEMPLATE.replace(
      "%REFERRAL%",
      referralCode
    ) + (role === "seller" ? "&role=seller" : "");

  return (
    <Suspense fallback={<></>}>
      <Modal
        isOpen
        onRequestClose={onClose}
        containerClassName="max-w-md w-full"
      >
        <Card>
          {inviteStyle === "referral" && (
            <>
              <CardTitle onClose={onClose}>
                {role === "seller"
                  ? i18n.t("invite_seller")
                  : i18n.t("invite_client")}
              </CardTitle>
              <CardBody>
                <ReferralCard
                  role={role}
                  onClose={onClose}
                  token={tokenData?.token}
                  inviteStyle={inviteStyle}
                  referralCode={referralCode}
                  referralLink={referralLink}
                />
              </CardBody>
            </>
          )}
          <CardTitle {...(inviteStyle === "direct" ? { onClose } : {})}>
            {role === "seller" &&
              inviteStyle === "direct" &&
              i18n.t("invite_seller")}
            {role === "seller" &&
              inviteStyle === "referral" &&
              i18n.t("invite_user_modal.send_email_with_invitation")}
            {role === "client" &&
              inviteStyle === "direct" &&
              i18n.t("invite_client")}
            {role === "client" && inviteStyle === "referral" && (
              <>
                <IfSeller>
                  {i18n.t("invite_user_modal.invite_new_client")}
                </IfSeller>
                <IfNotSeller>
                  {i18n.t("invite_user_modal.invite_your_friends")}
                </IfNotSeller>
              </>
            )}
          </CardTitle>
          <CardBody>
            <ClientInviteContainer role={role} inviteStyle={inviteStyle} />
          </CardBody>
        </Card>
      </Modal>
    </Suspense>
  );
};

InviteUserModal.path = "/invite-user/:inviteStyle/:role";

InviteUserModal.composePath = ({ inviteStyle, role } = {}) =>
  `invite-user/${inviteStyle}/${role}`;

InviteUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  inviteStyle: PropTypes.oneOf(["referral", "direct"]),
  role: PropTypes.oneOf(["client", "seller"]),
};

export default InviteUserModal;
