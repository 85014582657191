import React, { Suspense } from "react";
import PropTypes from "prop-types";

const CommissionDetailContainer = React.lazy(() =>
  import(
    "containers/sellers/CommissionDetailContainer" /* webpackChunkName: "CommissionDetailContainer" */
  )
);

const CommissionDetailModal = ({ commissionId, onClose }) => (
  <Suspense fallback={<></>}>
    <CommissionDetailContainer commissionId={commissionId} onClose={onClose} />
  </Suspense>
);

CommissionDetailModal.path = "/commission/:commissionId";

CommissionDetailModal.composePath = (commission) =>
  `commission/${commission.id}`;

CommissionDetailModal.propTypes = {
  commissionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CommissionDetailModal;
