import React, { Suspense } from "react";
import PropTypes from "prop-types";

const PaymentDetailProvider = React.lazy(() =>
  import(
    "containers/transactions/PaymentDetailProvider" /* webpackChunkName: "PaymentDetailProvider" */
  )
);
const PaymentDetailModal = ({ transactionId, onClose }) => (
  <Suspense fallback={<></>}>
    <PaymentDetailProvider transactionId={transactionId} onClose={onClose} />
  </Suspense>
);

PaymentDetailModal.path = "/payment/:transactionId";

PaymentDetailModal.composePath = (transaction) => `payment/${transaction.id}`;

PaymentDetailModal.propTypes = {
  transactionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PaymentDetailModal;
