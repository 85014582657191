import gql from "graphql-tag";

const paymentFragment = gql`
  fragment PaymentDetails on PaymentType {
    referenceNumber
    account {
      czech {
        prefix
        account
        bankCode
      }
      iban
    }
    __typename
  }
`;

const transactionFragment = gql`
  fragment TransactionDetails on TransactionType {
    id
    tokenAmount
    token {
      name
    }
    fiatAmount
    fiatCurrency
    state
    createdAt
    bonusTokenAmount
    paidAt
    tradedWith {
      id
      verboseName
      email
    }
    seller {
      id
      verboseName
      email
    }
    receiptUrls {
      transaction
      transactionFee
    }
    __typename
  }
`;

export const LIST_MY_TRANSACTIONS = gql`
  query ListMyTransactions(
    $perPage: Int!
    $commissionPayeeId: ID!
    $cursor: String
    $withPaymentDetails: Boolean = false
  ) {
    me {
      transactions(first: $perPage, after: $cursor) {
        edges {
          node {
            ...TransactionDetails
            commission(commissionPayeeId: $commissionPayeeId) {
              czkFiatAmount
              tokenAmount
            }
            payment @include(if: $withPaymentDetails) {
              ...PaymentDetails
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        exportUrl
      }
    }
  }
  ${transactionFragment}
  ${paymentFragment}
`;

export const LIST_CLIENT_TRANSACTIONS = gql`
  query ListClientTransactions(
    $perPage: Int!
    $clientId: ID!
    $cursor: String
    $withPaymentDetails: Boolean = false
  ) {
    me {
      id
      client(id: $clientId) {
        id
        transactions(first: $perPage, after: $cursor) {
          edges {
            node {
              ...TransactionDetails
              commission(commissionPayeeId: "CURRENT_USER") {
                czkFiatAmount
                tokenAmount
              }
              payment @include(if: $withPaymentDetails) {
                ...PaymentDetails
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          exportUrl(clientId: $clientId)
        }
      }
    }
  }
  ${transactionFragment}
  ${paymentFragment}
`;

export const GET_TRANSACTION_PAYMENT_DETAIL = gql`
  query GetTransactionPaymentDetail($id: ID!) {
    me {
      id
      verboseName
      email
    }
    transaction(id: $id) {
      ...TransactionDetails
      type
      transactionFeePercent
      transactionFee
      totalCost
      blockchain {
        state
        address
        explorerLink
      }
      payment {
        ...PaymentDetails
      }
      client {
        id
        verboseName
        email
      }
    }
  }
  ${transactionFragment}
  ${paymentFragment}
`;

export const GET_PENDING_TRANSACTIONS = gql`
  query GetPendingTransactions {
    me {
      transactions(type: MINT_CRYPTO_TO_POOL, state: PENDING) {
        edges {
          node {
            ...TransactionDetails
            type
            payment {
              ...PaymentDetails
            }
          }
        }
        pageInfo {
          endCursor
        }
      }
    }
  }
  ${transactionFragment}
  ${paymentFragment}
`;
