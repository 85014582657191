import React from "react";
import i18n from "i18n";

import ErrorCard from "components/error/ErrorCard";

const PermissionDenied = () => (
  <ErrorCard
    title={i18n.t("pages.permissions_error")}
    text={i18n.t("pages.permissions_error_text")}
  />
);

export default PermissionDenied;
