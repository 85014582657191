import React from "react";
import { Notification } from "@xixoio/components/Notifications";
import i18n from "i18n";

const IAMExpirationNotification = ({ expiresAt }) => {
  const expirationDate = new Date(expiresAt).toLocaleDateString("cs");
  return (
    <Notification icon="warning" cta={null}>
      <span className="block">
        {i18n.t("notifications.IAM.text_1")} <strong>{expirationDate}</strong>{" "}
        {i18n.t("notifications.IAM.text_2")}
      </span>
      {i18n.t("notifications.IAM.text_3")}{" "}
      <a
        href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`}
        className="link"
      >
        {i18n.t("notifications.IAM.text_4")}
      </a>
    </Notification>
  );
};

export default IAMExpirationNotification;
