import gql from "graphql-tag";
import i18n from "i18n";

import { tokenDetailsFragment } from "./tokens";

export const StatsFieldMap = {
  week: i18n.t("week"),
  month: i18n.t("financial_operations.period.month"),
  quarter: i18n.t("financial_operations.period.quarter"),
  year: i18n.t("financial_operations.period.year"),
  previousYear: i18n.t("financial_operations.period.previous_year"),
  all: i18n.t("total"),
};

export const CREATE_CLIENT = gql`
  mutation CreateClient($email: String!) {
    createClient(input: { email: $email }) {
      client {
        id
      }
    }
  }
`;

export const INVITE_CLIENT = gql`
  mutation InviteClient($email: String!, $style: String!, $role: String) {
    inviteClient(input: { email: $email, role: $role, style: $style }) {
      success
    }
  }
`;

export const SET_CLIENT_PHONE = gql`
  mutation setClientPhone($phone: String!, $identityId: ID) {
    updateClient(identityId: $identityId, input: { phone: $phone }) {
      client {
        id
        phone
      }
    }
  }
`;

export const SUBMIT_SELLER_AGREEMENT_DETAILS = gql`
  mutation submitSellerAgreementDetails(
    $details: UpdateSellerAgreementDetailsInput
    $bankAccount: BankAccountInput
  ) {
    updateClient(
      input: { sellerAgreementDetails: $details, bankAccount: $bankAccount }
    ) {
      client {
        id
        ... on MeType {
          sellerAgreementState
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

const clientEdgeNoteFragment = gql`
  fragment ClientEdgeNodeFragment on ClientConnectionTypeEdge {
    node {
      id
      verboseName
      email
      phone
      isSeller
    }
  }
`;

export const GET_CERTAIN_CLIENTS = gql`
  query GetCertainClients(
    $perPage: Int!
    $cursor: String
    $clients: [ID]
    $includingDeleted: Boolean = false
    $includingSelf: Boolean = false
  ) {
    me {
      clients(
        first: $perPage
        after: $cursor
        kind: ALL
        levelSpan: DESCENDANTS
        includingDeleted: $includingDeleted
        includingSelf: $includingSelf
        clientIds: $clients
      ) {
        edges {
          ...ClientEdgeNodeFragment
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${clientEdgeNoteFragment}
`;

export const SEARCH_CLIENTS = gql`
  query SearchClients(
    $perPage: Int!
    $cursor: String
    $includingDeleted: Boolean = false
    $includingSelf: Boolean = false
    $query: String
  ) {
    me {
      clients(
        first: $perPage
        after: $cursor
        kind: ALL
        levelSpan: DESCENDANTS
        includingDeleted: $includingDeleted
        includingSelf: $includingSelf
        query: $query
      ) {
        edges {
          ...ClientEdgeNodeFragment
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${clientEdgeNoteFragment}
`;

export const LIST_MY_CLIENTS = gql`
  query ListMyClients(
    $perPage: Int
    $cursor: String
    $includingDeleted: Boolean = false
  ) {
    me {
      clients(
        first: $perPage
        after: $cursor
        kind: ALL
        levelSpan: CHILDREN
        includingDeleted: $includingDeleted
        includingSelf: false
      ) {
        edges {
          node {
            id
            verboseName
            email
            phone
            verificationStatus
            portfolio(commissionPayee: CURRENT_USER) {
              id
              currentValue
              costs
              commissionFiat
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const intDateRangeStatsFragment = gql`
  fragment IntStats on IntDateRangeStatsType {
    week
    month
    quarter
    year
    previousYear
    all
    __typename
  }
`;

const decimalDateRangeStatsFragment = gql`
  fragment DecimalStats on DecimalDateRangeStatsType {
    week
    month
    quarter
    year
    previousYear
    all
    __typename
  }
`;

const commissionStatsItemFragment = gql`
  fragment CommissionStats on CommissionAmountType {
    fiat {
      amount
      currency
    }
    tokens {
      token {
        name
      }
      amount
    }
    __typename
  }
`;

const commissionDateRangeStatsFragment = gql`
  fragment CommissionStatsBreakdown on CommissionDateRangeStatsType {
    week {
      ...CommissionStats
    }
    month {
      ...CommissionStats
    }
    quarter {
      ...CommissionStats
    }
    year {
      ...CommissionStats
    }
    previousYear {
      ...CommissionStats
    }
    all {
      ...CommissionStats
    }
    __typename
  }
  ${commissionStatsItemFragment}
`;

export const GET_CLIENT_STATS = gql`
  query GetClientStats {
    me {
      investmentAmountStats(kind: ALL, levelSpan: CHILDREN) {
        ...DecimalStats
      }
      investmentCountStats(kind: ALL, levelSpan: CHILDREN) {
        ...IntStats
      }
      clientCountStats(kind: ALL, levelSpan: CHILDREN) {
        ...IntStats
      }
    }
  }
  ${intDateRangeStatsFragment}
  ${decimalDateRangeStatsFragment}
`;

export const GET_CLIENT_DETAILS = gql`
  query GetClientDetails($id: ID!, $currency: Currency = CZK) {
    me {
      id
      client(id: $id, includingSelf: true) {
        id
        identityId
        fullName
        verboseName
        phone
        email
        isSeller
        verificationStatus
        avatarImage(transformation: "w_400,h_400,c_fill/w_100")
        parent {
          id
          email
          verboseName
          fullName
        }
        portfolio(commissionPayee: CURRENT_USER) {
          id
          currentValue
          profit
          currency
          items {
            token {
              ...TokenDetails
            }
            costs
            currentValue
            tokenAmount
            profit
            commission {
              fiatAmount
              tokenAmount
            }
          }
        }
      }
    }
  }
  ${tokenDetailsFragment}
`;

export const GET_SELLER_DETAILS = gql`
  query GetSellerDetails($id: ID!) {
    me {
      id
      client(id: $id, includingSelf: true) {
        id
        verboseName
        phone
        email
        sellerCertDegree
        networkValue
        avatarImage(transformation: "w_400,h_400,c_fill/w_100")
        childrenSellerCount: childrenCount(kind: SELLERS)
        childrenClientsCount: childrenCount(kind: ALL)
        networkClientSize: descendantCount(kind: ALL)
        networkSellerSize: descendantCount(kind: SELLERS)
        ancestors {
          id
          email
          verboseName
        }
        investmentAmountStats(levelSpan: DESCENDANTS) {
          ...DecimalStats
        }
        clientCountStats(kind: SELLERS, levelSpan: DESCENDANTS) {
          ...IntStats
        }
        investmentCountStats(levelSpan: DESCENDANTS) {
          ...IntStats
        }
        clientStateCount {
          verified
          unverified
          tokenHolder
        }
        commissionStats(statsType: ALL) {
          all {
            fiat {
              currency
              amount
            }
          }
        }
        commission {
          fiatFraction
          tokenFraction
        }
        commissionInfo {
          salesMaxTopSeller
          salesNeedToNextLevel
          salesSecondarySellersNetwork
          salesSelf
          salesTopSeller
        }
      }
    }
  }
  ${intDateRangeStatsFragment}
  ${decimalDateRangeStatsFragment}
`;

const networkNodeSellersFragment = gql`
  fragment NetworkNodeSellersFragment on ClientType {
    id
    verboseName
    avatarImage(transformation: "w_400,h_400,c_fill/w_100")
    email
    networkSize: descendantCount(kind: ALL)
    portfolio {
      id
      currentValue
    }
    turnover {
      costs {
        currency
        amount
        commission
      }
      tokenPurchases {
        token {
          name
          project {
            title
          }
        }
        amount
        commission
      }
    }
  }
`;

const networkNodeClientsFragment = gql`
  fragment NetworkNodeClientsFragment on ClientType {
    id
    verboseName
    avatarImage(transformation: "w_400,h_400,c_fill/w_100")
    networkSize: descendantCount(kind: ALL)
    email
    isSeller
    verificationStatus
    portfolio {
      id
      currentValue
      costs
      currency
      items {
        costs
        currentValue
        tokenAmount
        token {
          name
          currency
        }
      }
    }
    turnover {
      costs {
        currency
        amount
        commission
      }
      tokenPurchases {
        token {
          name
          project {
            title
          }
        }
        amount
        commission
      }
    }
  }
`;

export const GET_SELLER_SELLERS = gql`
  query GetSellerSellers($id: ID!, $perPage: Int, $cursor: String) {
    me {
      client(id: $id, includingSelf: true) {
        id
        clients(
          kind: SELLERS
          levelSpan: CHILDREN
          first: $perPage
          after: $cursor
          includingDeleted: false
          includingSelf: false
        ) {
          edges {
            node {
              ...NetworkNodeSellersFragment
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${networkNodeSellersFragment}
`;

export const GET_SELLER_CLIENTS = gql`
  query GetSellerClients(
    $id: ID!
    $perPage: Int
    $cursor: String
    $clientState: ClientState
  ) {
    me {
      client(id: $id, includingSelf: true) {
        id
        clients(
          kind: ALL
          levelSpan: CHILDREN
          first: $perPage
          after: $cursor
          clientState: $clientState
          includingDeleted: false
          includingSelf: false
        ) {
          edges {
            node {
              ...NetworkNodeClientsFragment
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${networkNodeClientsFragment}
`;

export const GET_MASTER_SELLER_DETAILS = gql`
  query GetMasterSellerDetails {
    network {
      sellerCount: descendantCount(kind: SELLERS)
      directSellerCount: childrenCount(kind: SELLERS)
      userCount: descendantCount(kind: ALL)
      investmentAmountStats(levelSpan: INDIRECT_DESCENDANTS) {
        ...DecimalStats
      }
      clientCountStats(kind: SELLERS, levelSpan: DESCENDANTS) {
        ...IntStats
      }
      investmentCountStats(levelSpan: INDIRECT_DESCENDANTS) {
        ...IntStats
      }
    }
  }
  ${intDateRangeStatsFragment}
  ${decimalDateRangeStatsFragment}
`;

export const GET_MASTER_SELLER_SELLERS = gql`
  query GetMasterSellerSellers($perPage: Int, $cursor: String) {
    network {
      clients(
        kind: SELLERS
        levelSpan: CHILDREN
        first: $perPage
        after: $cursor
        includingDeleted: false
        includingSelf: false
      ) {
        edges {
          node {
            ...NetworkNodeSellersFragment
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${networkNodeSellersFragment}
`;

export const GET_MASTER_SELLER_CLIENTS = gql`
  query GetMasterSellerClients($perPage: Int, $cursor: String) {
    network {
      clients(
        kind: ALL
        levelSpan: CHILDREN
        first: $perPage
        after: $cursor
        includingDeleted: false
        includingSelf: false
      ) {
        edges {
          node {
            ...NetworkNodeClientsFragment
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${networkNodeClientsFragment}
`;

export const GET_DIRECT_COMMISSION_SUMMARY = gql`
  query GetDirectCommissionSummary {
    me {
      investmentAmountStats(
        kind: ALL
        levelSpan: CHILDREN
        includingSelf: false
      ) {
        ...DecimalStats
      }
      investmentCountStats(
        kind: ALL
        levelSpan: CHILDREN
        includingSelf: false
      ) {
        ...IntStats
      }
      commissionStats(statsType: DIRECT) {
        ...CommissionStatsBreakdown
      }
    }
  }
  ${intDateRangeStatsFragment}
  ${decimalDateRangeStatsFragment}
  ${commissionDateRangeStatsFragment}
`;

export const GET_INDIRECT_COMMISSION_SUMMARY = gql`
  query GetIndirectCommissionSummary {
    me {
      investmentAmountStats(levelSpan: INDIRECT_DESCENDANTS) {
        ...DecimalStats
      }
      investmentCountStats(levelSpan: INDIRECT_DESCENDANTS) {
        ...IntStats
      }
      commissionStats(statsType: INDIRECT) {
        ...CommissionStatsBreakdown
      }
    }
  }
  ${intDateRangeStatsFragment}
  ${decimalDateRangeStatsFragment}
  ${commissionDateRangeStatsFragment}
`;

export const GET_MONTHLY_COMMISSIONS = gql`
  query GetMonthlyCommissions($months: MonthsQuery!) {
    me {
      monthlyCommissions(months: $months) {
        month
        fiat {
          amount
          currency
        }
      }
      commissionsToPay(months: $months) {
        month
        fiat {
          amount
          currency
        }
      }
    }
  }
`;
