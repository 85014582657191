import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const CardTitle = ({ onClose, children, centered, className }) => {
  const cls = classNames("card__title relative flex items-center", className);
  const labelClass = classNames("flex-grow block", {
    "text-center pl-12": !!centered,
    "pr-12": !!onClose,
    "pl-12": !!(onClose && centered),
  });

  return (
    <h1 className={cls}>
      {onClose && (
        <button
          onClick={onClose}
          className="absolute right-0 mr-4 lg:mr-8 btn btn--text btn--sm btn--icon btn--icon-left"
          aria-label="Zavřít kartu"
          data-tooltip="Zavřít kartu"
        >
          <span className="btn__icon">
            <svg
              className="w-3.5 h-3.5"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4714 3.52858C12.7317 3.78892 12.7317 4.21103 12.4714 4.47138L4.47138 12.4714C4.21103 12.7317 3.78892 12.7317 3.52858 12.4714C3.26823 12.211 3.26823 11.7889 3.52858 11.5286L11.5286 3.52858C11.7889 3.26823 12.211 3.26823 12.4714 3.52858Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.52858 3.52858C3.78892 3.26823 4.21103 3.26823 4.47138 3.52858L12.4714 11.5286C12.7317 11.7889 12.7317 12.211 12.4714 12.4714C12.211 12.7317 11.7889 12.7317 11.5286 12.4714L3.52858 4.47138C3.26823 4.21103 3.26823 3.78892 3.52858 3.52858Z"
              />
            </svg>
          </span>
        </button>
      )}
      <span className={labelClass}>{children}</span>
    </h1>
  );
};

CardTitle.propTypes = {
  onClose: PropTypes.func,
  centered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default React.memo(CardTitle);
