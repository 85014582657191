import { useCallback, useState } from "react";
import debounce from "lodash/debounce";

/**
 * Like `useState` but debounced using lodash's debounce.
 *
 * To detect changes, only use the `signal` as it will change once the debounce
 * delay expires.
 * @param {any} defaultValue initial value
 * @param {number} delay debounce delay
 * @param {object} options debounce options
 */
export const useDebounce = (
  defaultValue,
  delay = 500,
  options = defaultOptions
) => {
  const [value, setValueImmediately] = useState(defaultValue);
  const [debouncing, setDebouncing] = useState(false);
  const [signal, setSignal] = useState(Date.now());

  const setValue = useCallback(
    (value) => {
      setValueImmediately(value);
      setDebouncing(true);
      triggerUpdate();
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    []
  );

  const triggerUpdate = useCallback(
    debounce(
      () => {
        setDebouncing(false);
        setSignal(Date.now());
      },
      delay,
      options
    ),
    []
  );

  return [
    value,
    setValue,
    {
      signal,
      debouncing,
    },
  ];
};

const defaultOptions = { leading: false, trailing: true };
