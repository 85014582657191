import { ApolloLink, fromError } from "@apollo/client";

import keycloak from "./keycloak";

const impersonationContext = {
  impersonator: null,
};

export const setImpersonator = (impersonator) => {
  impersonationContext.impersonator = impersonator;
};

export const getImpersonator = () => {
  return impersonationContext.impersonator;
};

export const storeImpersonatorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data.data.me && data.data.me.impersonatedBy) {
      setImpersonator(data.data.me.impersonatedBy);
    }
    return data;
  });
});

export const impersonatorMutationGuardLink = new ApolloLink(
  (operation, forward) => {
    const impersonator = getImpersonator();

    if (impersonator && impersonator.accessLevel === "READ_ONLY") {
      const isMutation = operation.query.definitions.some(
        (definition) =>
          definition.kind === "OperationDefinition" &&
          definition.operation === "mutation"
      );

      if (isMutation) {
        return fromError(
          new Error(
            "Cannot run mutations when impersonated with READ_ONLY access level."
          )
        );
      }
    }

    return forward(operation);
  }
);

export const setHeadersLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "client-name": "Moje XIXOIO",
      "client-version": "1.0.0",
      authorization: `JWT ${keycloak.token}`,
    },
  });

  return forward(operation);
});
