import React from "react";
import { Label } from "@xixoio/components/Label";
import classNames from "classnames";
import i18n from "i18n";

export const renderClientSearchMenuItem = (item, isSelected) => (
  <div
    className={classNames(
      "flex p-4 w-full items-center space-x-4 justify-between",
      { "bg-gray-600": isSelected }
    )}
  >
    <div className="overflow-hidden">
      <div
        className={classNames("font-semibold truncate", {
          "text-white-100": isSelected,
          "text-green-400": !isSelected,
        })}
      >
        {item.value}
      </div>
      <div className="text-xs font-normal text-white-100 text-opacity-50 truncate">
        {item.client.email}
      </div>
    </div>
    <Label>{item.client.isSeller ? i18n.t("seller") : i18n.t("client")}</Label>
  </div>
);
