import React, { useEffect, useState } from "react";
import useDebounce from "@rooks/use-debounce";
import useWindowSize from "@rooks/use-window-size";

import { BreakpointContext } from "./context";
import { getCurrentBreakpointName } from "./utils";

const BreakpointProvider = ({ children }) => {
  const { innerWidth } = useWindowSize();
  const [breakpoint, setBreakpoint] = useState(getCurrentBreakpointName());

  const updateBreakpoint = useDebounce(() => {
    setBreakpoint(getCurrentBreakpointName());
  }, 500);

  useEffect(() => {
    updateBreakpoint();
  }, [innerWidth, updateBreakpoint]);

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
};

export default BreakpointProvider;
