import React from "react";
import { Role } from "enums";
import PropTypes from "prop-types";

import { IfNotRole, IfRole } from "./IfRole";

export const IfSeller = ({ children }) => {
  return <IfRole role={Role.SELLER}>{children}</IfRole>;
};

export const IfNotSeller = ({ children }) => {
  return <IfNotRole role={Role.SELLER}>{children}</IfNotRole>;
};

IfSeller.propTypes = IfNotSeller.propTypes = {
  children: PropTypes.node,
};

export default IfSeller;
