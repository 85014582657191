import BuyModal from "./BuyModal";
import BuyOnBehalfModal from "./BuyOnBehalfModal";
import CommissionDetailModal from "./CommissionDetailModal";
import InviteUserModal from "./InviteUserModal";
import NonExistentBCWalletModal from "./NonExistentBCWalletModal";
import PaymentDetailModal from "./PaymentDetailModal";
import PhishingNotificationModal from "./PhishingNotificationModal";
import SellerContactModal from "./SellerContactModal";
import TransactionDetailModal from "./TransactionDetailModal";

export { default as BuyModal } from "./BuyModal";
export { default as BuyOnBehalfModal } from "./BuyOnBehalfModal";
export { default as CommissionDetailModal } from "./CommissionDetailModal";
export { default as ModalRenderer } from "./ModalRenderer";
export { default as PaymentDetailModal } from "./PaymentDetailModal";
export { default as SellerContactModal } from "./SellerContactModal";
export { default as TransactionDetailModal } from "./TransactionDetailModal";
export { default as InviteUserModal } from "./InviteUserModal";
export { default as NonExistentBCWalletModal } from "./NonExistentBCWalletModal";
export { default as PhishingNotificationModal } from "./PhishingNotificationModal";

export const ALL_MODALS = [
  BuyModal,
  BuyOnBehalfModal,
  CommissionDetailModal,
  PaymentDetailModal,
  InviteUserModal,
  SellerContactModal,
  TransactionDetailModal,
  NonExistentBCWalletModal,
  PhishingNotificationModal,
];
