import React from "react";
import { useQuery } from "@apollo/client";
import { Button } from "@xixoio/components/Button";
import { Notification } from "@xixoio/components/Notifications";
import i18n from "i18n";

import { InviteUserModal } from "modals";
import { TOKEN_REF_BONUS } from "queries/tokens";
import { useModal } from "utils/hooks";

const ReferralProgramPromoNotification = () => {
  const showInviteUserModal = useModal(InviteUserModal);
  const { data } = useQuery(TOKEN_REF_BONUS, {
    variables: {
      tokenName: "XIX",
    },
  });
  const token = data?.token ?? null;

  return (
    <Notification
      icon="info"
      cta={
        <Button
          onClick={() =>
            showInviteUserModal({ role: "client", inviteStyle: "referral" })
          }
          secondary
          small
          textCenter
          className="whitespace-nowrap"
        >
          {i18n.t("more_informations")}
        </Button>
      }
    >
      {i18n.t("notifications.refferal", {
        refferal_reward: token?.referralBonusAmount + " " + token?.name,
      })}
    </Notification>
  );
};

export default ReferralProgramPromoNotification;
