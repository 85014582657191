import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  cs: {
    translation: {
      tokens: "Tokeny",
      token: "Token",
      client: "Klient",
      seller: "Obchodník",
      current_value: "Současná hodnota",
      purchase_value: "Nákupní hodnota",
      commission: "Provize",
      buy: "Koupit",
      profit: "Zisk",
      value: "Hodnota",
      period: "Období",
      commission_fiat: "Provize fiat",
      show: "Zobrazit",
      error: "Došlo k chybě",
      close: "Zavřít",
      date: "Datum",
      transaction_id: "ID transakce",
      volume_of_transaction: "Objem transakce",
      volume_of_transactions: "Objem transakcí",
      state: "Stav",
      volume: "Objem",
      show_next: "Zobrazit další",
      type: "Typ",
      no_transactions: "Žádné transakce",
      transactions: "Transakce",
      seller_client: "Obchodník / Klient",
      transaction_commission: "Transakce / Provize",
      detail: "Detail",
      back: "Zpět",
      go_to_xixoio: "Přejít do aplikace XIXOIO",
      finish: "Dokončit",
      phone_number: "Telefonní číslo",
      continue: "Pokračovat",
      save_was_not_successful:
        "Uložení se nezdařilo. Zkuste prosím akci zopakovat.",
      yes: "Ano",
      no: "Ne",
      skip: "Přeskočit",
      front_side: "přední",
      back_side: "zadní",
      repeat: "Zopakovat",
      upload_again: "Nahrát znovu",
      market_price: "Tržní hodnota",
      profit_loss: "Zisk/Ztráta",
      maxLength: `Zadejte nejvýše {{value}} znaků.`,
      country: "Země",
      thanks: "Děkujeme",
      no_clients: "Žádní klienti.",
      no_sellers: "Žádní obchodníci.",
      portfolio: "Portfolio",
      volume_of_trades: "Objem obchodů",
      sold_tokens: "Prodané tokeny",
      belongs_under: "Patří pod obchodníka",
      certification_of_level: "Certifikát úrovně {{level}}",
      number_of_transactions: "Počet transakcí",
      fee: "Poplatek za transakci",
      total: "Celkem",
      token_value_when_bought: "Hodnota tokenu v době pořízení",
      recipient_of_token: "Příjemce tokenu",
      status_of_transaction: "Stav transakce",
      repeat_purchase: "Zopakovat nákup",
      transaction_detail_error: "Při načítání detailu transakce došlo k chybě.",
      there_was_an_error: "Došlo k chybě",
      loading_transaction_detail: "Načítám detail transakce ...",
      transaction_detail: "Detail transakce",
      volume_token: "Objem transakce v tokenech",
      amount_to_pay: "Částka k zaplacení",
      token_1_value_when_bought: "Hodnota 1 tokenu v době pořízení",
      thanks_for_order: "Děkujeme za Vaši objednávku.",
      email: "Email",
      phone: "Telefon",
      bank_contact: "Bankovní kontakt",
      referral_reward: "Odměna za doporučení",
      more_informations: "Více informací",
      copy_to_clipboard: "Zkopírovat do schránky",
      more_informations_in_xixout: "Více informací v XIXOUT",
      number_of_clients: "Počet klientů",
      change: "Změna",
      week: "Týden",
      month: "Měsíc",
      year: "Rok",
      all: "Vše",
      ytd: "Od začátku roku do dnešního dne",
      load_next: "Načíst další",
      buy_tokens: "Koupit tokeny",
      overview: "Přehled",
      my_seller: "Můj obchodník",
      my_sellers: "Moji obchodníci",
      transactions_list_error:
        "Při načítání seznamu transakcí došlo k problému:",
      seller_group: "Obchodní skupina",
      my_seller_group: "Moje obchodní skupina",
      invite_client: "Pozvat klienta",
      invite_seller: "Pozvat obchodníka",
      my_clients: "Moji klienti",
      no_project_description: "Popis projektu není k dispozici.",
      countries: {
        sk: "Slovensko",
        cz: "Česká republika",
      },
      buy_flow: {
        complete_original_order: "Dokončit původní objednávku",
        earlier_order:
          "Našli jsme starší objednávku tokenů {{tokenName}}, která ještě nebyla uhrazena. Určitě chcete vytvořit novou objednávku?",
        several_older_orders:
          "Evidujeme vícero starších objednávek, které ještě čekají na vaši úhradu. Určitě chcete vytvořit novou objednávku?",
        demo_scx:
          "Nyní pořizujete demo verzi Tokenu SCX, na který nejsou navázána žádná práva. Limit nákupu je omezen na 1 Kč.",
      },
      payment: {
        waiting: "Čeká na platbu",
        finished: "Dokončená",
        cancelled: "Zrušená",
        thanks_for_order_text:
          "Do 24h Vás telefonicky kontaktují kolegové ze zákaznické péče XIXOIO s informacemi k zadání platebních údajů pro tuto transakci.",
        informations: "Platební údaje",
        amount: "Částka",
        account_number: "Číslo účtu",
        bank_code: "Kód banky",
        variable_symbol: "Variabilní symbol",
        iban: "IBAN",
        qr: "QR kód",
      },

      modals: {
        purchase_price: "Zvolte prosím výši Vašeho nákupu.",
        purchase_min: "Musí být nejméně {{amount}}",
        purchase_max: "Limit nákupu je {{amount}}",
        unable_to_buy_token: "Token nelze koupit",
        now_buying_tokens: "Teď kupujete Tokeny {{tokenName}}",
        buying_for: " pro {{clientName}}",
        unknown_course: "Neznámý kurz",
        including_fee: "Včetně poplatku {{fee}}%",
        enter_amount: "Zadejte částku",
        enter_number_of_tokens: "Zadejte počet tokenů",
        toggle_amount_tokens: "Přepnout mezi částkou a množstvím tokenů",
        confirmation: "Potvrzení",
        number_of_tokens_to_buy: "Počet tokenů k zakoupení",

        fee_is_included:
          "Poplatek za obchodní pokyn je již zahrnut v celkové výši objednávky.",
        value_of_token: "Hodnota tokenu",
        method_of_payment: "Způsob úhrady",
        bank_transfer: "Bankovní převod",
        confirm: "Potvrdit",
        course_has_been_changed: "Pozor, změnil se kurz",
        course_has_been_changed_while_buying:
          "Během nákupního procesu se změnil kurz. Proveďte prosím nákup znovu.",
        new_purchase: "Nový nákup",
        error_while_buying: "Při objednávce nastala chyba.",
        error_while_buying_try_again:
          "Nastala neznámá chyba. Zkuste nákup opakovat. Pokud problém přetrvává, kontaktujte podporu.",
      },
      tos_confirmation: {
        terms_and_conditions: "Všeobecných obchodních podmínek",
        product_conditions: "Produktových podmínek",
        declaration_of_acquirer: "Prohlášení nabyvatele",
        price_list: "Sazebníku",
        and: "a",
        accepting_terms_and_conditions: "Přijímám obchodní podmínky",
        confirmation_1:
          "Potvrzuji, že jsem se seznámil/a před odesláním této objednávky s aktuálním zněním",
        confirmation_2: " porozuměl/a jsem jejich obsahu a souhlasím s nimi.",
        is_required: "Pro nákup musíte souhlasit se smlouvou a sazebníkem.",
      },
      chart: {
        error: "Při načítání grafu došlo k problému:",
        development: "Vývoj zhodnocení tokenu ",
      },
      client_actions: {
        transaction_history: "Historie transakcí",
        buy_for_client: "Nakoupit pro klienta",
        verify_client_identity: "Ověřit identitu klienta",
      },
      client_add: {
        client_already_exists:
          "Klient s tímto emailem je již na XIXOIO zaregistrován.",
        seller_agreement_is_not_signed:
          "Pro plné využívání vašeho obchodního profilu zbývá ještě podepsat Smlouvu o spolupráci a obchodním zastoupení.",
        done: "Hotovo!",
        add_client: "Přidejte klienta",
        after_form_is_sent:
          "Po odeslání formuláře bude klientovi zřízen nový účet na Moje XIXOIO a také mu pošleme email s instrukcemi, jak se do svého účtu přihlásit.",
        error_at_adding_client: "Přidání klienta selhalo:",
        email_is_required: "Bez emailu se neobejdeme.",
        we_need_valid_email: "Zadejte prosím platný email.",
        add_new_client: "Přidat nového klienta",
        client_was_added: "Klient byl úspěšně přidán.",
        ok: "V pořádku",
      },
      client_invite_form: {
        invite_sent: "Pozvánka byla úspěšně odeslána.",
        refferal_link_sent:
          "Na zadanou adresu odešleme email s vaším referenčním odkazem a instrukcemi jak se zaregistrovat.",
        registration_link_sent:
          "Na zadanou adresu odešleme email s instrukcemi jak se zaregistrovat. Odesílatelem pozvánky bude přímo XIXOIO.",
        to_send_invite: "Odeslat pozvánku",
      },
      client_portfolio: {
        empty_portfolio: "Klient zatím nevložil peníze",
        current_value: "Současná hodnota / Zisk",
      },
      client_profile: {
        client_profile: "Profil klienta",
      },
      commission_breakdown: {
        volume: "Objem",
        volume_of_trades: "Objem obchodů",
      },
      my_commissions: {
        your_commissions: "Vaše aktuální provize",
      },
      next_commission_level: {
        sell_tokens_in_volume: "Prodat tokeny v objemu",
        cert_degree_1: "Získat obchodní certifikaci I. úrovně.",
        cert_degree_2: "Získat obchodní certifikaci II. úrovně.",
        cert_degree_3: "Získat obchodní certifikaci III. úrovně.",
        private_purchase_amount:
          "Učinit nákup tokenů XIX alespoň za 10 000 Kč.",
        signed_agreement_addendum: "Podepsat dodatek k obchodní smlouvě.",
        for_higher_level:
          "Pro dosažení vyšší provizní úrovně zbývá splnit podmínky:",
      },
      token_selection: {
        choose_token_for_purchase: "Zvolte token k nákupu",
      },
      commission_report_list: {
        detailed_statement: "Detailní výpis",
      },
      commission_detail: {
        loading_detail_of_commission: "Načítám detail provize ...",
        error: "Při načítání detailu provize došlo k chybě",
        commission_from_transaction: "Provize z transakce",
        fiat_amount: "Výše provize fiat",
        token_amount: "Výše provize token",
        volume_fiat: "Objem transakce fiat",
        payment_status: "Stav výplaty",
        originator_of_transaction: "Původce transakce",
        level_of_commission_fiat: "Úroveň provize fiat",
      },
      commission_list: {
        no_commission: "Zatím žádné provize.",
        volume_transaction: "Objem transakce",
      },
      error_card: {
        back_to_overview: "Zpět na přehled",
      },
      financial_operations: {
        period: {
          all: "Bez časového omezení",
          today: "Dnes",
          week: "Tento týden",
          month: "Současný měsíc",
          previous_month: "Minulý měsíc",
          quarter: "Kvartál",
          year: "Současný rok",
          previous_year: "Minulý rok",
        },
        choices: {
          all: "Všechny transakce a provize",
          all_transactions: "Všechny transakce",
          purchase_transactions: "Transakce - vlastní nákup",
          sale_transactions: "Transakce - přímý prodej",
          commission: "Všechny provize",
        },
        all_tokens: "Všechny tokeny",
        choose_client_seller: "Vybrat klienta nebo obchodníka",
        time_restriction: "Časové omezení",
      },
      identity_verification: {
        container: {
          title: "Využívejte XIXOIO na 100 %",
        },
        debrief: {
          pending:
            "Ověření vaší identity vyžaduje ještě manuální kontrolu. Proces může chvíli trvat. Budeme Vás o jeho průběhu informovat. Děkujeme za strpení.",
          approved: "Odteď můžete naplno využívat všechny funkce XIXOIO.",
          seller_contract:
            "Jednou z podmínek jak se stát obchodníkem je podpis elektronické smlouvy. V následujících krocích Vás požádáme o vyplnění několika základních údajů. Po schválení procesu Vám bude zaslána smlouva k podepsání v elektronické formě.",
          seller_contract_pending:
            "Jednou z podmínek jak se stát obchodníkem je podpis elektronické smlouvy. V následujících krocích Vás požádáme o vyplnění několika základních údajů. Po schválení Vašich údajů Vám bude zaslána smlouva k podepsání v elektronické formě.",
          thanks_for_documents: "Děkujeme Vám, dokumenty byly úspěšně nahrány",
          continue_to_fill_details: "Pokračovat k vyplnění údajů",
          show_electronic_contract: "Zobrazit znění elektronické smlouvy",
          cant_verify_identity: "Vaši identitu se nám nepodařilo ověřit.",
          contact_please: "Kontaktujte prosím",
          our_support: "naši podporu",
        },
        document_type_selection: {
          identity_verification: "Ověření identity",
        },
        error: {
          invalid_type_of_file: "Neplatný typ souboru",
          file_must_be_jpg_or_png: "Nahraný soubor musí být typu jpg nebo png.",
          network_error: "Chyba připojení",
          cant_connect_to_server:
            "Nemůžeme se připojit k našemu serveru. Zkontrolujte, prosím, své připojení.",
          invalid_file_content: "Neplatný obsah souboru",
          uploaded_file_is_not_image: "Nahraný soubor není obrázek.",
          unknown_error: "Neznámá chyba",
          unknown_error_happened: "Nastala neočekávaná chyba",
          invalid_file_extension: "Neplatný typ souboru.",
          extension_must_be_jpg_or_png:
            "Soubor musí mít koncovku jpg nebo png.",
          invalid_image_size: "Neplatný rozměr obrázku",
          file_is_damaged: "Soubor je zřejmě poškozen. Šířka nebo výška je 0.",
          external_api_error: "Chyba při komunikaci s externí službou",
          error_at_documents_verification:
            "Nastala chyba při komunikaci se službou ověřující pravost dokumentů. Naši vývojáři byli o problému již informováni. Případně, prosím, zkuste akci zopakovat.",
          invalid_type_of_document: "Neplatný typ dokumentu",
          different_upload_type:
            "Zdá se, že nahráváte jiný dokument než momentálně očekáváme. Zkontrolujte, že jste zvolili správný soubor.",
        },
        funding_source: {
          title:
            "Dle zákona 253/2008 Sb. si Vás dovolujeme požádat, v rámci tzv. kontroly klienta, o sdělení původu finančních prostředků, prosím zvolte převažující původ Vašich finančních prostředků:",
          funding_source: "Zdroj financování",
          select_funding_source: "Zvolte zdroj financování",
          employment_sector: "Zaměstnanecký sektor",
          select_employment_sector: "Zvolte zaměstnanecký sektor",
        },
        intro: {
          contact_our_team:
            "Pokud budete mít nějaký problém či dotaz, náš zákaznický tým Vás bude moci kontaktovat a rychle věc vyřešit.",
          your_phone_number: "Vaše telefonní číslo",
          we_need_your_phone_number: "Bez telefonního čísla se neobejdeme.",
          phone_number_format: "Zadejte prosím číslo ve formátu +420123456789",
        },
        pep: {
          documents_were_uploaded:
            "Děkujeme Vám, dokumenty byly úspěšně nahrány",
          politically_exposed_person: "Jste politicky exponovaná osoba?",
          more_info_political_exposed_person:
            "Bližší informace k politicky exponované osobě",
        },
        upload_documents: {
          selfie: {
            title: "A jste to opravdu vy?",
            text: "Nyní nahrajte Vaše aktuální selfie.",
            button: "Nahrát selfie",
          },
          button: "Nahrát {{side}} stranu",
          start_with_front: "Začněme tou přední.",
          continue_with_back: "Pokračujte nahráním zadní strany.",
          residence_permission: {
            title: "Potvrzení k pobytu",
            text: "Je to jednoduché a bezpečné. Stačí scan/fotografie přední a zadní strany dokladu.",
          },
          id: {
            title: "Občanský průkaz",
            text: "Je to jednoduché a bezpečné. Stačí scan/fotografie přední a zadní strany dokladu.",
          },
          drv_licence: {
            title: "Řidičský průkaz",
            text: "Pro ověření Vaší identity pomocí řidičského průkazu budeme potřebovat nahrát vyfocenou přední stranu dokladu.",
            button: "Nahrát řidičský průkaz",
          },
          passport: {
            title: "Cestovní pas",
            text: "Pro ověření Vaší identity pomocí cestovního pasu budeme potřebovat nahrát vyfocenou přední stranu dokladu.",
            button: "Nahrát cestovní pas",
          },
          tooltip:
            "Zákon 253/2008 Sb. opravňuje povinné osoby při provádění identifikace a kontroly kopírovat průkazy totožnosti,  včetně následného zpracování pro účely a ve lhůtě dle tohoto zákona v rámci obchodního vztahu. Poskytnutím svého průkazu totožnosti berete tento účel zpracování na vědomí.",
          issues: {
            ID_front: "Přední strana občanského průkazu",
            ID_back: "Zadní strana občanského průkazu",
            drv_licence_front: "Přední strana řidičského průkazu",
            drv_licence_back: "Zadní strana řidičského průkazu",
            selfie_unspecified: "Selfie",
            wait_for_previous_document:
              "Vyčkejte než se nahraje předchozí dokument.",
            title: "Narazili jsme ovšem na pár zádrhelů",
            text: "Zkuste je prosím vyřešit. Pokud se vám to nedaří, pokračujte kliknutím na tlačítko „Přeskočit“.",
          },
        },
      },
      nav: {
        customer_support: "Zákaznická podpora",
        documents: "Dokumenty",
        personal_data_processing: "Pravidla zpracování osobních údajů",
        general_terms_and_conditions: "Všeobecné obchodní podmínky",
        your_refferal_code: "Váš referenční kód",
        your_refferal_code_link: "Váš odkaz s referenčním kódem",
        text_if_seller:
          "Tento odkaz slouží pro Vaše přátele a klienty. Pokud jej využijí k registraci do XIXOIO, přímo vaše účty propojíme a Vy získát provizi z jejich transakcí.",
        text_if_not_seller:
          "Tento odkaz slouží pro Vaše přátele a známé. Pokud jej využijí k registraci do XIXOIO, dostanete za jejich první zrealizovaný nákup odměnu {{refferal_text}}. Stejnou odměnu od nás dostane i Váš kontakt. Podrobné podmínky doporučitelského programu naleznete ",
        here: "zde",
      },
      pagination: {
        previous_site: "Předchozí stránka",
        next_site: "Další stránka",
      },
      my_portfolio: {
        title: "Aktuální hodnota Vašeho portfolia",
      },
      seller_contact: {
        step_1: {
          entrepreneur: "Podnikatel (IČO)",
          non_entrepreneur: "Nepodnikatel, nejsem veden jako OSVČ",
          choose_legal_form: "Zvolte Vaši právní formu",
          choose_legal_form_text:
            "Prosím zvolte vaši právní formu. V případě, že jste veden jako Osoba samostatně výdělečně činná (podnikatel) zvolte tuto možnost.",
        },
        step_2: {
          personal_info_title: "Vaše osobní údaje",
          personal_info_title_entrepreneur: "Vaše obchodní údaje",
          address: "Adresa trvalého bydliště",
          address_entrepreneur: "Adresa místa podnikání",
          personal_info:
            "Prosím vyplňte Vaše osobní údaje potřebné pro podpis elektronické smlouvy.",
          personal_info_entrepreneur:
            "Prosím vyplňte Vaše obchodní údaje potřebné pro podpis elektronické smlouvy.",
          ico: {
            name: "IČO",
            placeholder: "Příklad 53418237",
            required: "Bez IČO se neobejdeme.",
            invalid: "Zadejte platné IČO.",
          },
          dic: {
            name: "DIČ",
            placeholder: "Příklad CZ00029947",
            invalid: "Zadejte platné DIČ.",
          },
          company_name: {
            name: "Vaše obchodní jméno",
            placeholder: "Příklad Petr Novák - Investování",
            required: "Bez vašeho obchodního jména se neobejdeme.",
          },
          birth_date: {
            name: "Datum narození",
            required: "Bez data narození se neobejdeme.",
            pattern:
              "Zadejte platné datum ve formátu DD.MM.YYYY, např. 1.1.1980.",
          },
          first_name: {
            name: "Jméno",
            placeholder: "Jan",
            required: "Bez vašeho jména se neobejdeme.",
          },
          last_name: {
            name: "Příjmení",
            placeholder: "Novák",
            required: "Bez vašeho příjmení se neobejdeme.",
          },
          town: {
            name: "Město",
            placeholder: "Praha",
            required: "Město pro smlouvu potřebujeme.",
          },
          zip_code: {
            name: "PSČ",
            required: "Bez PSČ se neobejdeme.",
            pattern: "Zadejte platné PSČ.",
          },
          street_and_number: {
            name: "Ulice a číslo popisné",
            placeholder: "Vinohradská 1",
            required: "Bez vaší adresy se neobejdeme.",
          },
        },
        step_3: {
          czech_bank_number: "České bankovní číslo účtu",
          iban: "IBAN",
          submission_error: {
            text_1:
              "Je nám líto, ale při pokusu o uložení údajů elektronické smlouvy došlo k problému.",
            text_2: "Pokud problém přetrvává, kontaktujte prosím naši",
            text_3: "zákaznickou podporu",
          },
          your_bank_number: "Číslo Vašeho bankovního účtu",
          your_bank_number_text:
            "Číslo bankovního účtu slouží pro automatické vyplácení Vašich provizí z prodeje tokenů.",
          account_number_format: "Formát čísla účtu",
          prefix: {
            name: "Předčíslí",
            invalid: "Zadejte platné předčíslí účtu.",
          },
          account_number: {
            name: "Číslo účtu",
            required: "Bez čísla účtu se neobejdeme.",
            invalid: "Zadejte platné číslo účtu.",
          },
          bank_code: {
            name: "Kód banky",
            required: "Kód banky budeme potřebovat.",
            invalid: "Zadejte platný kód banky.",
          },
          iban_field: {
            name: "IBAN číslo účtu",
            required: "Čislo účtu budeme potřebovat.",
            pattern:
              "Číslo účtu není ve správném formátu. Zkontrolujte prosím, že se jedná o IBAN.",
            invalid:
              "Číslo účtu není platné. Zkontrolujte prosím, že se jedná o IBAN.",
          },
        },
        sucess: {
          text_1:
            "Všechny potřebné údaje byly úspěšně zapsány do systému. Náš obchodní tým už pracuje na přípravě Vaši elektronické smlouvy.",
          text_2:
            "Po vyhotovění elektronické smlouvy budete informován e-mailem a notifikací v aplikaci XIXOIO.",
          text_3: "Pokračovat na úvodní stránku",
        },
      },
      transaction_card: {
        download_receipt: "Stáhnout doklad o nabytí tokenu",
        download_receipt_fee: "Stáhnout doklad o zaplacení poplatku",
        size_of_reward: "Výše odměny",
        volume_in_tokens: "Objem transakce v tokenech",
        bonus_tokens: "Bonusové tokeny",
        volume_fiat: "Objem transakce fiat",
        variable_symbol: "Variabilní symbol",
        written_at_blockchain: "Zápis transakce v blockchainu",
      },
      transaction_list: {
        no_transactions: "Zatím žádné transakce.",
        initiator: "Zprostředkovatel",
        traded_with: "Směněno s kým",
      },
      notifications: {
        title: "Upozornění",
        mark_all_as_read: "Označit jako přečtené",
        no_notifications: "Nemáte žádné upozornění",
        IAM: {
          text_1: "Upozorňujeme, že Vám dne",
          text_2:
            "vyprší platnost dokladu, který jste u nás použil/a pro verifikaci.",
          text_3: "Kontaktuje prosím",
          text_4: "zákaznickou podporu",
        },
        IAM_incomplete: {
          verify_identity: "Ověřit identitu",
          text_1:
            "Zatím ještě nemůžete nakupovat. 100% fungování aplikace získáte až ověřením identity.",
          text_2:
            "Probíhá ověření Vaší identity, vyčkejte prosím. Pro dotazy prosím kontaktuje",
          text_3: "zákaznickou podporu",
          text_4:
            "Vaši identitu se nám nepodařilo ověřit, kontaktujte prosím naši",
          text_5:
            "Platnost Vašeho dokumentu vypršela. Prosím proveďte revalidaci dokumentu v nastavení Vašeho účtu.",
        },
        OTP: {
          turn_on: "Zapnout",
          text_1: "Zapněte si dvoufázové ověření pro větší bezpečnost.",
        },
        pending_transactions: {
          text_1: "Zobrazit pokyny k platbě",
          text_2:
            "Vaše objednávka tokenů č. {{order_number}} čeká na zaplacení.",
          text_3: "Některé vaše objednávky tokenů čekají na zaplacení:",
        },
        phishing:
          "Informace a doporučení XIXOIO při podezření na tzv. phishingový útok",
        refferal:
          "Pozvěte své přátele a získejte společně s nimi odměnu {{refferal_reward}}.",
        pending_signature:
          "Právě ověřujeme údaje pro Vaši Smlouvu o spolupráci a obchodním zastoupení. Po ověření Vám smlouvu zašleme a po jejím podpisu budete moci naplno využívat Váš obchodní profil.",
        missing_seller_details: {
          text_1: "Vyplnit smluvní údaje",
          text_2:
            "Pro plné využívání vašeho obchodního profilu zbývá ještě podepsat Smlouvu o spolupráci a obchodním zastoupení. Začněte vyplněním údajů pro smlouvu.",
        },
      },
      clients: {
        name: "Klienti",
        detail_error: "Při načítání detailu klienta došlo k problému:",
        detail: "Detail klienta",
        portfolio_value: "Hodnota portfolia",
        tokenized_projects: "Tokenizované projekty",
        stats_error: "Při načítání statistik došlo k problému:",
        last_client_transactions: "Poslední transakce klienta",
        show_all_transactions: "Zobrazit všechny transakce",
        commission_error: "Při načítání informací o provizi došlo k problému:",
      },
      articles: {
        live: "Živě",
        show_all_articles: "Zobrazit všechny články",
      },
      timeline: {
        in_which_phase: "V jaké jsme fázi",
      },
      token_list: {
        ipco_tokens: "IPCO tokeny",
        price_of_token: "Cena tokenu",
        money_collected: "Vybraný kapitál",
        period: "Perioda",
        real_estate_tokens: "Nemovitostní tokeny",
        etx_info:
          " V 1. tranši bylo vybráno přes 14 mil. Zahájení prodeje Tokenu ETX v 2. tranši je plánováno do konce roku 2022. Aktuální index Tokenu ETX odpovídá emisní ceně pro 2. tranši.",
        ytd: "YTD",
        company_tokens: "Firemní tokeny",
        buy_token: "Koupit token",
        portfolio_error: "Při načítání portfolia došlo k problému:",
      },
      token_view: {
        graph_error: "Při načítání grafu došlo k problému: ",
        change_view: "Změnit zobrazení",
        show_token: "Zobrazit token",
        purchased_tokens: "Zakoupených tokenů",
        no_token_to_buy: "Momentálně není žádný token určen ke koupi.",
        value_of_my_assets: "Hodnota mých aktiv",
      },
      xixout_msg: {
        latest_posts: "Nejnovější příspěvky na",
        mark_all_as_read: "Označit vše jako přečtené",
        error: "Při načítání notifikací došlo k chybě.",
        all_read:
          "Vidíme, že jste se ještě nepodívali do komunitní sítě XIXOUT. Přihlašovací údaje jsou identické s těmi, které používáte pro vstup do Moje XIXOIO. Klikněte na tlačítko níže a můžete začít komunikovat s tokenizovanými firmami.",
        text_1: "Všechny novinky máte přečtené, ale i tak",
        text_2: "se můžete podívat, co je na XIXOUT",
        text_3: "nového.",
        enter_xixout: "Vstoupit do XIXOUT",
      },
      export: {
        export: "Exportovat",
        csv: "CSV",
      },
      financial_operations_overview: {
        error: "Při načítání seznamu pohybů došlo k problému:",
      },
      navbar: {
        main_site: "Hlavní stránka",
        logo: "Logo",
        toggle_menu: "Přepnout menu",
        master_overview: "Master přehled",
        invite_friends: "Pozvi přátele",
        get: "Získej",
        blockchain_explorer: "Blockchain explorer",
        edit_my_profile: "Upravit můj profil",
        log_out: "Odhlásit se",
        impersonification_read_only:
          "Impersonifikace v režimu pro čtení. Akce provádějící změny skončí chybou.",
        impersonification:
          "Impersonifikace je aktivní. Dejte pozor při provádění jakýchkoliv změn.",
        error_getting_informations_about_user:
          "Nepodařilo se získat informace o přihlášeném uživateli.",
        search: "Hledat klienta nebo obchodníka",
      },
      portfolio_screen: {
        error: "Při načítání portfolia došlo k problému:",
      },
      sellers: {
        name: "Obchodníci",
        statistics_error: "Při načítání statistiky došlo k problému:",
        reward_from_direct_sale: "Odměna z přímého zákaznického prodeje",
        reward_from_indirect_sale: "Odměna z nepřímého prodeje",
        commission_error: "Při načítání seznamu provizí došlo k problému:",
        provision_to_be_paid: "Provize k vyplacení",
        sum_of_commissions: "Kumulovaný součet provizí",
        total_commission: "Celková provize",
        monthly_commission: "Měsíční výpisy provizí",
        error_at_seller_contact:
          "Při načítání kontaktu na obchodníka došlo k problému:",
        no_seller_assigned: "Nemáte přiřazeného žádného obchodníka.",
        phone_of_seller: "Telefon obchodníka",
        seller_detail_error:
          "Při načítání detailu obchodníka došlo k problému:",
        superior_seller: "Nadřazený obchodník",
        go_to_master_overview: "Přejít na Master přehledu",
        managed_volume: "Spravovaný objem obchodní skupiny",
        tooltip:
          "Aktuální hodnota nakoupených tokenů v obchodní síti bez vlastních nákupů obchodníka.",
        group_transactions: "Transakce obchodní skupiny",
        my_team: "Můj tým",
        seller_team: "Tým obchodníka",
        sellers_clients: "Klienti obchodníka",
        all_clients: "Všichni klienti",
        tokenholder: "Tokenholder",
        verified_user: "Verifikovaný uživatel",
        unverified_user: "Neverifikovaný uživatel",
      },
      utils: {
        clients_of_seller_group: "Klienti obchodní skupiny",
        clients_total: "Klienti celkem",
        total_to_higher_level: "Celkem do vyšší úrovně",
        strongest_seller_group: "Nejsilnější obchodní skupina",
        other_seller_groups: "Ostatní obchodní skupiny",
        other_direct_clients: "Ostatní přímí klienti",
        tokenholders: "Tokenholdeři",
        verified_users: "Verifikovaní uživatelé",
        unverified_users: "Neverifikovaní uživatelé",
      },
      invite_user_modal: {
        invite_new_client: "Pozvat nového klienta",
        invite_your_friends: "Pozvěte své přátele",
        send_email_with_invitation: "Odeslat email s pozvánkou",
      },
      wallet: {
        wallet_not_found: "Peněženka nenalezena",
        text: "Vaše peněženka zatím není aktivní. Peněženku získáte spolu s Vaším prvním nákupem po zapsání tokenů do aplikace XIXOIO.",
      },
      phishing: {
        title:
          " Informace a doporučení XIXOIO při podezření na tzv. phishingový útok",
        text_1:
          "Pokud máte podezření, že se někdo snaží získat nebo z Vás vylákat Vaše přístupové údaje, resp. máte podezření na tzv. phishingový útok, postupujte prosím dle níže uvedených doporučení a kontaktujte nás nebo vašeho obchodního zástupce.",
        text_2:
          "XIXOIO tým ani obchodní zástupci nikdy nevolají s žádostí, abyste jim sdělili vaše přístupové údaje či společně provedli platbu na účet, který vám daná osoba na telefonu sdělí.",
        text_3: "Proto vám doporučujeme:",
        text_4:
          "1. Vždy provádět nákupy jakýchkoliv tokenů pouze v aplikaci Moje XIXOIO.",
        text_5:
          "2. Při nákupu s nikým nesdílet obrazovku a nikomu neposkytovat přihlašovací údaje do Moje XIXOIO.",
        text_6:
          "3. Vždy následovat pouze platební instrukce, které jsou komunikovány po zadání objednávky v Moje XIXOIO.",
        text_7:
          "4. Vždy obdržíte kontrolní e-mail „Nová objednávka tokenů č.XXXXXX“ potvrzující vaši objednávku tokenů.",
        text_8:
          "5. V případě, že jste kontaktováni osobou XIXOIO, avšak osobu neznáte nebo si nejste jisti důvěryhodností kontaktujícího, prosím, telefonát ukončete a kontaktujte oficiální kontakty XIXOIO na info@xixoio.com nebo na telefonním čísle +420 776 441 472. Pokud jste vpéči obchodního zástupce, můžete taktéž kontaktovat XIXOIO společně.",
        text_9:
          "6. Zapněte si dvoufázové ověření identity pro Moje XIXOIO (návod naleznete pod „Správa mého účtu“ v Moje XIXOIO“)",
        text_10:
          "7. Pro jistotu si pravidelně obměňujte svá přihlašovací hesla a dodržujte zásady tvorby bezpečnosti hesla (minimálně 8 znaků - kombinovat velká a malá písmena, speciální znaky a číslo).",
        text_11:
          "Chraňte své cennosti a vždy své uživatelské účty, ať se jedná o klasickou FIAT měnu nebo vaše tokeny a další aktiva. Ať jsou na nich uloženy vaše finanční prostředky, nebo jakýkoliv jiný majetek.",
      },
      pages: {
        clients_error: "Při načítání klientů došlo k problému:",
        add_client: "Přidat klienta",
        list_of_clients: "Seznam klientů",
        my_commissions: "Moje provize",
        history: "Historie",
        history_of_provisions: "Historie provizí",
        overview_of_transactions: "Přehled transakcí",
        identity_verification:
          "Ověření identity ještě není dokončeno. Určitě chcete stránku opustit?",
        is_not_verified_partially:
          "Abychom vám mohli umožnit nákup tokenů, musíme dle zákona nejprve ověřit vaši identitu.",
        is_verified_partially:
          "Abychom vám mohli umožnit nákup tokenů, musíme dle zákona nejprve ověřit vaši identitu, což jsme ještě zcela nedokončili.",
        title_1:
          "Pro nákup tokenů je nutné ověřit vaši identitu dvěma doklady totožnosti dle zákona o AML.",
        title_2: "Vyberte prosím typ prvního dokladu.",
        id_card: "Občanský průkaz",
        residence_permit: "Průkaz o povolení k pobytu",
        non_existent_user:
          "Tento klient neexistuje nebo nejste jeho obchodník.",
        client_data_error: "Údaje klienta nebylo možné získat.",
        choose_type_of_document: "Vyberte prosím typ druhého dokladu.",
        master_seller_error: "Při načítání přehledu došlo k problému:",
        direct_sellers: "Přímí obchodníci",
        all_sellers: "Všichni obchodníci",
        number_of_users: "Počet uživatelů",
        transactions_of_entire_network: "Transakce celé obchodní sítě",
        xixoio_master_seller: "XIXOIO Master Seller",
        not_found: "Stránka nenalezena",
        not_found_text:
          "Omlouváme se, ale vámi hledanou stránku se nám nepodařilo najít.",
        permissions_error: "Nedostatečné oprávnění",
        permissions_error_text: "Pro přístup na tuto stránku nemáte oprávnění.",
        tokens_overview: "Přehled tokenů",
        my_portfolio: "Moje portfolio",
        my_transactions: "Moje transakce",
        seller_contract_form:
          "Vyplnění formuláře elektronické smlouvy ještě nebylo dokončeno. Určitě chcete stránku opustit?",
        electronic_contract_data: "Údaje elektronické smlouvy",
      },
      commissions: {
        waiting_for_payout: "Čeká na vyplacení",
        commission_paid: "Provize vyplacena",
        waiting_for_manual_control: "Čeká na manuální kontrolu",
      },
      translations: {
        funding_source: {
          employed: "Příjmy ze zaměstnání",
          self_employed: "Příjmy z podnikání",
          rentals: "Příjmy z pronájmu",
          property_settlement: "Příjmy z majetkového vyrovnání",
          capital_assets: "Příjmy z kapitálového majetku",
          other_income: "Ostatní příjem výše neuvedený",
        },
        employment_sector: {
          education: "Sektor vzdělávání",
          armed_forces: "Sektor ozbrojených složek",
          health_care: "Sektor zdravotnictví",
          transport: "Sektor dopravy",
          public_administration: "Sektor ostatní veřejné správy",
          finance_insurance: "Sektor financí a pojišťovnictví",
          telecommunications: "Sektor telekomunikací",
          energy: "Sektor energetický",
          itc: "Sektor ITC",
          industrial: "Sektor průmyslový",
          business: "Sektor obchodní",
          agricultural: "Sektor zemědělský",
        },
      },
    },
  },
  en: {
    translation: {
      tokens: "Tokens",
      token: "Token",
      client: "Client",
      seller: "Seller",
      current_value: "Current value",
      purchase_value: "Purchase value",
      commission: "Commission",
      buy: "Buy",
      profit: "Profit",
      value: "Value",
      period: "Period",
      commission_fiat: "Fiat ommission",
      show: "Show",
      error: "Error occured",
      close: "Close",
      date: "Date",
      transaction_id: "transaction ID ",
      volume_of_transaction: "transaction volume",
      volume_of_transactions: "Volume of transactions",
      state: "State",
      volume: "volume",
      show_next: "show next",
      type: "Type",
      no_transactions: "no transaction",
      transactions: "Transactions",
      seller_client: "Trader/ Client",
      transaction_commission: "Transsaction/ Commission",
      detail: "Detail",
      back: "Back",
      go_to_xixoio: "Go to XIXOIO application",
      finish: "Finish",
      phone_number: "Phone number",
      continue: "Continue",
      save_was_not_successful:
        "Saving was not successful. Please repeat the action.",
      yes: "Yes",
      no: "No",
      skip: "Skip",
      front_side: "Front",
      back_side: "Back",
      repeat: "Repeat",
      upload_again: "Upload again",
      market_price: "Market price",
      profit_loss: "Profit/Loss",
      maxLength: "Give maximim {{value}} symbols.",
      country: "Country",
      thanks: "Thank you",
      no_clients: "No clients",
      no_sellers: "No traders.",
      portfolio: "Portfolio",
      volume_of_trades: "Volume of trades",
      sold_tokens: "Sold tokens",
      belongs_under: "Belongs under the trader",
      certification_of_level: "Certificate of level {{level}}",
      number_of_transactions: "Number of transactions",
      fee: "Fee for transaction",
      total: "Total",
      token_value_when_bought: "Value of token in time of purchase",
      recipient_of_token: "Recipient of token",
      status_of_transaction: "State of transaction",
      repeat_purchase: "Repeat the purchase",
      transaction_detail_error:
        "Error occurred when loading the transaction detail.",
      there_was_an_error: "Error occurred",
      loading_transaction_detail: "Loading transaction detail ...",
      transaction_detail: " Transaction Detail",
      volume_token: "Transaction volume in tokens",
      amount_to_pay: "Amount to be paid",
      token_1_value_when_bought: "Value of token 1 at time of purchase",
      thanks_for_order: "Thank you for your order.",
      email: "Email",
      phone: "Phone number",
      bank_contact: "Bank contact",
      referral_reward: "Reward for recommendation",
      more_informations: "More information",
      copy_to_clipboard: "Copy to the inbox",
      more_informations_in_xixout: "More information on XIXOUT",
      number_of_clients: "Number of clients",
      change: "Change",
      week: "Week",
      month: "Month",
      year: "Year",
      all: "All",
      ytd: "From the beginning of the year untill today",
      load_next: "Load next",
      buy_tokens: "Buy tokens",
      overview: "Overview",
      my_seller: "My trader",
      my_sellers: "My traders",
      transactions_list_error: " A problem occurred when uploading the list:",
      seller_group: "Trader group",
      my_seller_group: "My trader group",
      invite_client: "Invite the client",
      invite_seller: "Invite the trader",
      my_clients: "My client",
      no_project_description: "Project description is not available.",
      countries: {
        sk: "Slovakia",
        cz: "Czech Republic",
      },
      buy_flow: {
        complete_original_order: "Complete the original order",
        earlier_order:
          "We found an older token order {{tokenName}}, which has not been paied yet. Are you sure you want to create a new order?",
        several_older_orders:
          "We register several older orders that have still been waiting for your payment. Are you sure you want to create a ner order?",
        demo_scx:
          "You are now purchasing a demo version of Token SCX, for whioch there are no rights. The limit for the purcchase is 1 Kč.",
      },
      payment: {
        waiting: "Waiting for the payment",
        finished: "Finished",
        cancelled: "Cencelled",
        thanks_for_order_text:
          "You will be contacted by colleagues from the XIXOIO customer care service in 24 hours in order to give you information on payment information for this transaction.",
        informations: "Payment information",
        amount: "Amount",
        account_number: "Account number",
        bank_code: "Bank code",
        variable_symbol: "Variable symbol",
        iban: "IBAN",
        qr: "QR code",
      },

      modals: {
        purchase_price: "Please choose amount of your purchase.",
        purchase_min: "Minimum amount{{amount}}",
        purchase_max: "Limit of the purchase is {{amount}}",
        unable_to_buy_token: "Token cannot be bought",
        now_buying_tokens: "You are now buying tokens {{tokenName}}",
        buying_for: " for {{clientName}}",
        unknown_course: "Unknown course",
        including_fee: "Including the fee of {{fee}}%",
        enter_amount: "Enter the amount",
        enter_number_of_tokens: "Enter the amount of tokens",
        toggle_amount_tokens: "Switch between the sum and token amount",
        confirmation: "Confirmation",
        number_of_tokens_to_buy: "Number of tokens to be paid",

        fee_is_included:
          "The fee for the trade instruction is already included in the total amount of the order.",
        value_of_token: "Value of token ",
        method_of_payment: "Method of payment",
        bank_transfer: "Bank transaction",
        confirm: "Confirm",
        course_has_been_changed: "Be aware, the course has changed",
        course_has_been_changed_while_buying:
          "The course has changed during the purcahse. Please make the purcahse again.",
        new_purchase: "New purcahse",
        error_while_buying: "An error has occurred during the order.",
        error_while_buying_try_again:
          "An unknown error occurrred. Please try to repet the purcahse. Please contact th customer support, If the problem presists.",
      },
      tos_confirmation: {
        terms_and_conditions: "General trade conditions ",
        product_conditions: "Product conditions",
        declaration_of_acquirer: "Acquirer declaration ",
        price_list: "Price list",
        and: "and",
        accepting_terms_and_conditions:
          "I accept the trade terms and conditions",
        confirmation_1:
          "I confirm, that I got to know the actual version before sending the order.",
        confirmation_2: " I understood the content and agree with it.",
        is_required:
          "For purcahese, it is required to agree with the contact and price list.",
      },
      chart: {
        error: "An error occurred When uploading the graf:",
        development: "Development of the growth value of the token ",
      },
      client_actions: {
        transaction_history: "Transaction history",
        buy_for_client: "Purchase for client",
        verify_client_identity: "Verify the client identity",
      },
      client_add: {
        client_already_exists:
          "Client with this email is already registered at XIXOIO.",
        seller_agreement_is_not_signed:
          "For the full use of your business profile there remains the signing of the Agreement of cooperation and dealership.",
        done: "Done!",
        add_client: "Add a client",
        after_form_is_sent:
          "After the form is send, a new cleint accont on My XIXOIO will be opened up for the client and we will also send him/her an email with signing up instructions.",
        error_at_adding_client: "Adding of the client failed:",
        email_is_required: "We will not manage without an email.",
        we_need_valid_email: "ZEnter a valid email please.",
        add_new_client: "PAdd a new client",
        client_was_added: "The client was successfully added.",
        ok: "All right",
      },
      client_invite_form: {
        invite_sent: "The invitation was sent",
        refferal_link_sent:
          "We will send and email with your refferal link and registration instructions to the given adress.",
        registration_link_sent:
          "We will send an eamil with registration instructions to the given adress. The sender of the invitation will be XIXOIO.",
        to_send_invite: "Send an invitation",
      },
      client_portfolio: {
        empty_portfolio: "The client has not put money in yet ",
        current_value: "Current value / Profit",
      },
      client_profile: {
        client_profile: "Client profile",
      },
      commission_breakdown: {
        volume: "Volume",
        volume_of_trades: "Volume of trades",
      },
      my_commissions: {
        your_commissions: "Your current provision",
      },
      next_commission_level: {
        sell_tokens_in_volume: "Sell tokens in volume",
        cert_degree_1: "Obtain level I trade certification.",
        cert_degree_2: "Obtain level II trade certification.",
        cert_degree_3: "Obtain level III trade certification.",
        private_purchase_amount:
          "Make a purcahse of Token XIX for at least 10 000 Kč.",
        signed_agreement_addendum:
          "Sign an ammendment to the trade agreement .",
        for_higher_level:
          "In order to achieve a higher commission position, following conditions are remaining to be met:",
      },
      token_selection: {
        choose_token_for_purchase: "Choose a token for the purcahse",
      },
      commission_report_list: {
        detailed_statement: "Detailed extract",
      },
      commission_detail: {
        loading_detail_of_commission: "Loading the provision detail ...",
        error: "Error occurred while loading the provision detail",
        commission_from_transaction: "Provision from the transaction",
        fiat_amount: "Level of provision fiat",
        token_amount: "Level of provision token",
        volume_fiat: "Volume of transaction fiat",
        payment_status: "State of payment",
        originator_of_transaction: "Originator of transaction",
        level_of_commission_fiat: "Level of provision fiat",
      },
      commission_list: {
        no_commission: "No commissions yet.",
        volume_transaction: "Volume of transaction",
      },
      error_card: {
        back_to_overview: "Back to the overview",
      },
      financial_operations: {
        period: {
          all: "Without time limit",
          today: "Today",
          week: "This week",
          month: "Current month",
          previous_month: "Past month",
          quarter: "Quarter",
          year: "Current year",
          previous_year: "Last year",
        },
        choices: {
          all: "All transactions and provisions",
          all_transactions: "All transactions",
          purchase_transactions: "Transactions - own purcahse",
          sale_transactions: "Transaction - direct sale",
          commission: "All commissions",
        },
        all_tokens: "All tokens",
        choose_client_seller: "Choose a klient or trader",
        time_restriction: "Time limit",
      },
      identity_verification: {
        container: {
          title: "Keep using XIXOIO up to 100 %",
        },
        debrief: {
          pending:
            "Verification of your identity still needs to be manually checked. Th proces might last for a while. We will inform you about the progress. Thank you for your patience.",
          approved: "Since now you can fully use all of the XIXOIO functions.",
          seller_contract:
            "One of the conditions for becomming the trader is the signing of an electronic agreement. In the following steps we will ask you to fill in some of the basic information. After approving the process, you will be send an agreement to be electronicaly signed.",
          seller_contract_pending:
            "One of the conditions for becomming the trader is the signing of an electronic agreement. In the following steps, we will ask you to fill in some the basic information. After approving your data, you will be send an agreement to be electronicaly signed.",
          thanks_for_documents:
            "Thank you, the documents have been successfully uploaded.",
          continue_to_fill_details: "PContinu to fill in the data",
          show_electronic_contract:
            "Show the version of the electronic agreement",
          cant_verify_identity: "We did not manage to verify your identity.",
          contact_please: "Please contact",
          our_support: "our support",
        },
        document_type_selection: {
          identity_verification: "Identity verification,",
        },
        error: {
          invalid_type_of_file: "Invalid type of file",
          file_must_be_jpg_or_png:
            "The uploaded file must be in jpg or png form.",
          network_error: "Conection error",
          cant_connect_to_server:
            "We are not able to connect to our server. Please check your connection.",
          invalid_file_content: "Invalid file content",
          uploaded_file_is_not_image: "The uploaded file is not an image.",
          unknown_error: "Unknown error",
          unknown_error_happened: "Unknown error occurred",
          invalid_file_extension: "Unknown tyoe of file.",
          extension_must_be_jpg_or_png:
            "The file must have a jpg or png ending.",
          invalid_image_size: "Invalid image size",
          file_is_damaged:
            "The file is probably damaged. The width or height is 0.",
          external_api_error:
            "Error with the communication with the external service ",
          error_at_documents_verification:
            "Error occurred in the communication with the service which verifies authenticity of the documents. Our developer have already been informed. Eventually please try  repeating the action.",
          invalid_type_of_document: "Invalid document type",
          different_upload_type:
            "It seems like you are uploading a different document than we are expecting. Please check you have chosen the correct file.",
        },
        funding_source: {
          title:
            "According to Act we would like to ask you, under the so called client control, to tell the origin of financial means, please choose the prevailing origin of your financial funds:",
          funding_source: "Source of financing",
          select_funding_source: "Select a financing source",
          employment_sector: "Employment sector",
          select_employment_sector: "Choose the employment sector",
        },
        intro: {
          contact_our_team:
            "In case of having a problem or question, our customer team will be able to contact you and solve the matter quickly.",
          your_phone_number: "Your phone number",
          we_need_your_phone_number:
            "We will not manage without you phone number.",
          phone_number_format:
            "Please enter the number in the form +420123456789",
        },
        pep: {
          documents_were_uploaded:
            "Thank you, the documents have been successfully uploaded.",
          politically_exposed_person: "Are you a politically exposed person?",
          more_info_political_exposed_person:
            "More information on the politically exposed person",
        },
        upload_documents: {
          selfie: {
            title: "And is it truely you?",
            text: "Now, please upload your current selfie.",
            button: "Upload selfie",
          },
          button: "Upload the side {{side}} ",
          start_with_front: "Lets start with the front.",
          continue_with_back: "Continue with uploading the back side.",
          residence_permission: {
            title: "Confirmation of residence",
            text: "It is simple and safe. Scan or a picture of front and back side of the document is enough.",
          },
          id: {
            title: "Identity card",
            text: "It is simple and safe. Scan or a picture of front and back side of the document is enough.",
          },
          drv_licence: {
            title: "Driving licence",
            text: "In order to verify your identity through the driving licence, we will need to upload front side of the document.",
            button: "Upload the driving licence",
          },
          passport: {
            title: "Passport",
            text: "In order to verify your identity through the passport, we will need to upload a picture of the front side of the document.",
            button: "Upload the passport",
          },
          tooltip:
            "The Act 253/2008 Sb. enables obliged persons to copy identity cards when performing identification and control,  including the following processing for the purposes and period according to this Act within the business relationship. By providing your identy card you are confirming your awarness of this purpose of processing.",
          issues: {
            ID_front: "Front side of the identity card.",
            ID_back: "Back side of the identity card",
            drv_licence_front: "Front side of the driving licence",
            drv_licence_back: "Back side of the driving licence",
            selfie_unspecified: "Selfie",
            wait_for_previous_document:
              "Wait until the previous document is uploaded.",
            title: "However we came across several hitches",
            text: "Please, try to solve them. If you are unable to solve them, continue by clicking on „Skip“.",
          },
        },
      },
      nav: {
        customer_support: "Customer service",
        documents: "Documents",
        personal_data_processing: "Rules for personal data processing",
        general_terms_and_conditions: "General terms and conditions",
        your_refferal_code: "Your refferel code",
        your_refferal_code_link: "Your link with a refferal code",
        text_if_seller:
          "This link servers for your friends and clients. If they use it for the registraction into XIXOIO, we will connect your accounts directly and you will get commissions from theis transactions.",
        text_if_not_seller:
          "This link serves for your friends. If they use it for the registraction into XIXOIO , you will receive a reward for their first compeleted purcchase {{refferal_text}}. Also your contact will receive the same reward. You can find the detailed conditions of the delivery programe ",
        here: "here",
      },
      pagination: {
        previous_site: "Previous page",
        next_site: "Next page",
      },
      my_portfolio: {
        title: "Current value of your portfolio",
      },
      seller_contact: {
        step_1: {
          entrepreneur: "Enterpreneur (Business ID)",
          non_entrepreneur:
            "Non - Enterpreneur, I am not registered as self-employed person",
          choose_legal_form: "Choose your legal form",
          choose_legal_form_text:
            "Please choose your legal form. I case your are registered as self-employed (enterpreneur), choose this option.",
        },
        step_2: {
          personal_info_title: "Your personal data",
          personal_info_title_entrepreneur: "Your business data",
          address: "Adress of permanent residence",
          address_entrepreneur: "Adress of business",
          personal_info:
            "Please fill out your personal data necessary for the signature of the electronic contract.",
          personal_info_entrepreneur:
            "Please fill out your business data necessary for the signature of the electronic contract.",
          ico: {
            name: "Business ID",
            placeholder: "Example 53418237",
            required: "Business ID is required.",
            invalid: "Enter valid Business ID.",
          },
          dic: {
            name: "DIČ",
            placeholder: "Example CZ00029947",
            invalid: "Enter valid DIČ.",
          },
          company_name: {
            name: "Your business name",
            placeholder: "Example: Petr Novák - Investments",
            required: "Your business name is required.",
          },
          birth_date: {
            name: "Date of birth",
            required: "Date of birth is required.",
            pattern:
              "Enter a valid date in the form DD.MM.YYYY, for e.g. 1.1.1980.",
          },
          first_name: {
            name: "Name",
            placeholder: "Jan",
            required: "Your name is required.",
          },
          last_name: {
            name: "Surname",
            placeholder: "Novák",
            required: "Your surname is required.",
          },
          town: {
            name: "City",
            placeholder: "Praha",
            required: "the city is required.",
          },
          zip_code: {
            name: "Postal Code",
            required: "Postal code is required.",
            pattern: "Enter valid postal code.",
          },
          street_and_number: {
            name: "Street and number",
            placeholder: "Vinohradská 1",
            required: "Your adress is required.",
          },
        },
        step_3: {
          czech_bank_number: "Czech bank account number ",
          iban: "IBAN",
          submission_error: {
            text_1:
              "we are sorry but error has occurred when entering the data for the elctrical agreement.",
            text_2: "In case the problems prevail, please contact our",
            text_3: "customer support",
          },
          your_bank_number: "Your bank account number ",
          your_bank_number_text:
            "Bank account number servers for the pamynet of commissions from the token sales.",
          account_number_format: "Account number format",
          prefix: {
            name: "Pre-code",
            invalid: "Enter a valid pre code of the account number.",
          },
          account_number: {
            name: "Account number",
            required: "The account number is required.",
            invalid: "Enter a valid account number.",
          },
          bank_code: {
            name: "Bank code",
            required: "Bank code is requiredbudeme potřebovat.",
            invalid: "Enter a valid bank code.",
          },
          iban_field: {
            name: "IBAN account number",
            required:
              "The account number is required. Please check that it is IBAN",
            invalid:
              "Account number is not valid. Please check that it is IBAN.",
          },
        },
        sucess: {
          text_1:
            "All of the necessary details have been successfully signed into the system. Our sales team is working on the preparation of your electronic contract.",
          text_2:
            "After the execution of the electronic contact you will be informed by email and notification in the application XIXOIO.",
          text_3: "Continue to the intro page",
        },
      },
      transaction_card: {
        download_receipt: "Download the receipt on token acquisition",
        download_receipt_fee: "Download the receipt on the fee payment",
        size_of_reward: "Amount of reward",
        volume_in_tokens: "Transaction volume in tokens",
        bonus_tokens: "Bonus tokens",
        volume_fiat: "Transaction volume fiat",
        variable_symbol: "Variable symbol",
        written_at_blockchain: "Entry of the transaction to the blockchain",
      },
      transaction_list: {
        no_transactions: "No transactions so far.",
        initiator: "Intermediary",
        traded_with: "Traded with whom",
      },
      notifications: {
        title: "Notifications",
        mark_all_as_read: "Mark all as read",
        no_notifications: "No notifications",

        IAM: {
          text_1: "Attention please, on the day ",
          text_2:
            "The validity of your document, which you used for your verification will expire.",
          text_3: "Please contact",
          text_4: "customer service",
        },
        IAM_incomplete: {
          verify_identity: "Verify the identity",
          text_1:
            "You are not able to buy yet. You will get the complete 100 % function of the applicationa following the identity verification.",
          text_2:
            "The identity verification is in process, please wait. For any questions please contact",
          text_3: "customer service",
          text_4:
            "We did not manage to verify your identity, please contact our",
          text_5:
            "The validity of your document has expired. Please revalidate your document in the setting of your account.",
        },
        OTP: {
          turn_on: "Turn on",
          text_1: "Turn on the two-step verification for more safety.",
        },
        pending_transactions: {
          text_1: "Show the payment orders",
          text_2:
            "Your order of tokens no. {{order_number}} is waiting for the payment.",
          text_3: "Some of your token orders are waiting for the payment:",
        },
        phishing:
          " XIXOIO Information and recommendations when a so called phishing attack is suspected.",
        refferal:
          "Invite your friends and together receive a reward {{refferal_reward}}.",
        pending_signature:
          "We are now verifing your Agreement on cooperation and sales representation. Following the verification, we will send you the Contract and after signing it, you will be able to fully use your business profile.",
        missing_seller_details: {
          text_1: "Enter the contract data",
          text_2:
            "For the full use of your business profile, you need to sign the Agreement on cooperation and sales representation. Start with entering the data for the contract.",
        },
      },
      clients: {
        name: "Clients",
        detail_error: "An Error occurred when loading the client details:",
        detail: "Client Details",
        portfolio_value: "Portfolio value",
        tokenized_projects: "Tokenized projects",
        stats_error: "An error occured when loading the statistics:",
        last_client_transactions: "Last transaction of the client",
        show_all_transactions: "Show all transactions",
        commission_error:
          "An Error occurred when loading the commission details:",
      },
      articles: {
        live: "Live",
        show_all_articles: "Show all articles",
      },
      timeline: {
        in_which_phase: "What phase are we in",
      },
      token_list: {
        ipco_tokens: "IPCO tokens",
        price_of_token: "Token price",
        money_collected: "Capital raised",
        period: "Period",
        real_estate_tokens: "Real estate tokens",
        etx_info:
          " There has been raised over 14 mil in the 1st tranche. Start of the sale of Token ETX in the 2nd trenche is planned until the end of 2022. Current index of the Token ETX corresponds with the issue price for the 2nd tranche.",
        ytd: "YTD",
        company_tokens: "Company tokens",
        buy_token: "Buy token",
        portfolio_error: "An Error occurred when loading the portfolio:",
        detail: "Client Details",
      },
      token_view: {
        graph_error: "An Error occurred when loading the graph:",
        detail: "Client Details",
        change_view: "Change the view",
        show_token: "Show token",
        purchased_tokens: "Purchased tokens",
        no_token_to_buy: "Currently there is no token for purchase.",
        value_of_my_assets: "Value of my assets",
      },
      xixout_msg: {
        latest_posts: "Latest posts on",
        mark_all_as_read: "Mark all as read",
        error: "An Error occurred when uploading the notifications.",
        all_read:
          "We can see that you haven't looked into the community network XIXOUT. Registering informations are identical with those you are using to enter the application My XIXOIO. Click bellow and you can start communicating with the tokenized companies.",
        text_1: "You have read all of the news but still",
        text_2: " you can have a look what is new at XIXOUT.",
        text_3: ".",
        enter_xixout: "Visit XIXOUT now",
      },
      export: {
        export: "Export",
        csv: "CSV",
      },
      financial_operations_overview: {
        error: "An errorr has occured when uploading the list of moves:",
      },
      navbar: {
        main_site: "Main site",
        logo: "Logo",
        toggle_menu: "Switch menu",
        master_overview: "Master overview",
        invite_friends: "Invite friends",
        get: "Get",
        blockchain_explorer: "Blockchain explorer",
        edit_my_profile: "Edit my profile",
        log_out: "Log out",
        impersonification_read_only:
          "Impersonification for read only. Action with demanding changes will end up with an error.",
        impersonification:
          "Impersonification is active. Be carefull when making any changes.",
        error_getting_informations_about_user:
          "failed to get information about the registered user.",
        search: "Search client or trader",
      },
      portfolio_screen: {
        error: "An error occurred when uploading the portfolio:",
      },
      sellers: {
        name: "Traders",
        statistics_error: "An error occurred when uploading the statistics:",
        reward_from_direct_sale: "Reward from the direct customer sale",
        reward_from_indirect_sale: "reward from the indirect sale",
        commission_error:
          "An error occurred when uploading the list of commissions:",
        provision_to_be_paid: "Commissions to be paied",
        sum_of_commissions: "Cumulative sum of commissions",
        total_commission: "Celková provize",
        monthly_commission: "Monthly commissions",
        error_at_seller_contact:
          "An error occurred when uploading the trader contact:",
        no_seller_assigned: "There is no trader assigned.",
        phone_of_seller: "Phone number of the seller",
        seller_detail_error:
          "An error occurred when uploading the detail of the trader:",
        superior_seller: "Superior trader",
        go_to_master_overview: "Go to master overview",
        managed_volume: "Managed volume of the business group",
        tooltip:
          "Current value of tokens bought in the trade network without own purchases of the trader.",
        group_transactions: "Transaction of the business group",
        my_team: "My team",
        seller_team: "Trader's team",
        sellers_clients: "Trader's clients",
        all_clients: "All clients",
        tokenholder: "Tokenholder",
        verified_user: "Verified user",
        unverified_user: "Unverified user",
      },
      utils: {
        clients_of_seller_group: "Clients of the trader group",
        clients_total: "Clients in total",
        total_to_higher_level: "Total up to a a higher level",
        strongest_seller_group: "The strongest trader group",
        other_seller_groups: "Other trader groups",
        other_direct_clients: "Other direct clients",
        tokenholders: "Tokenholders",
        verified_users: "Verified user",
        unverified_users: "Unverified user",
      },
      invite_user_modal: {
        invite_new_client: "Invite a new client ",
        invite_your_friends: "Invite your friends",
        send_email_with_invitation: "Send an email with the invitation",
      },
      wallet: {
        wallet_not_found: "Wallet was not found",
        text: "Your wallet is not active yet. You will get your wallet along with your first purchase following the token registration in the XIXOIO application.",
      },
      phishing: {
        title:
          " XIXOIO Information and recommendations when a phishing attack is of suspect",
        text_1:
          "If you have the suspicion that someone is trying to elict your access data, if you have a suspicion on a so called phishing attack, please follow the instructions bellow and contact us or your sales representative.",
        text_2:
          "Niether XIXOIO team nor our sales representatives ever call to ask you for your access data or make a payment together to an account which the given person tells you on the phone..",
        text_3: "That is the reason we recommend:",
        text_4: "1. Always buy any tokens only in the aplication My XIXOIO.",
        text_5:
          "2. When making the purchase never share screen with anyone and never give your access data to My XIXOIO.",
        text_6:
          "3. Always follow only those payment instructions that are being communicated after making an order in My XIXOIO.",
        text_7:
          "4. You will always get a control email „New token order no.XXXXXX“ confirming your token order.",
        text_8:
          "5. In case you are being contacted by a person form XIXOIO but you do not know the person or you are not sure about their credibility, please end the phone call and contact the official XIXOIO contacts on info@xixoio.com or phone number  +420 776 441 472. If you have your own sales representative, you can contact XIXOIO together.",
        text_9:
          "6. Turn on the two phase identity verification for My XIXOIO (you will find the instructions under “Managment of my account“ in My XIXOIO)",
        text_10:
          "7. Change your access passwords regularly and follow the principles for save passwords (minimum of 8 signs and lower case and upper case letters, numbers and special characters).",
        text_11:
          "Protect your value and all of your user accounts, wheather it is classical FIAT currency or your tokens and other actives. Weather you have your financial means or any other property.",
      },
      pages: {
        clients_error: "An error occurred when uploading the client:",
        add_client: "Add a client",
        list_of_clients: "List of clients",
        my_commissions: "My commissions",
        history: "History",
        history_of_provisions: "History of commissions",
        overview_of_transactions: "Overview of transactions",
        identity_verification:
          "Identity verification is not complete. Are you sure you want to leave the page?",
        is_not_verified_partially:
          "In order to enable the token purcahse, we first have to verify your identity based on the Act on AML.",
        is_verified_partially:
          "In order to enable the token purcahse, based on the Act we first have to verify your identity, which has not been finished yet.",
        title_1:
          "For the token purchase it is neccessary to verify your identity with two ID documents, based on the Act on AML.",
        title_2: "Please choose the first type of document.",
        id_card: "Identity card",
        residence_permit: "Residence permission card ",
        non_existent_user:
          "This client does not exist or you are not a trader.",
        client_data_error: "Unable to get client data.",
        choose_type_of_document: "Please choose the second type of documents.",
        master_seller_error: "An error occurred when uploading the overview:",
        direct_sellers: "Direct traders",
        all_sellers: "All traders ",
        number_of_users: "Number of users",
        transactions_of_entire_network: "Transaction of the entire network",
        xixoio_master_seller: "XIXOIO Master Seller",
        not_found: "Page not found",
        not_found_text:
          "We are sorry but we did not manage to find the page you are looking for.",
        permissions_error: "Insufficient verification",
        permissions_error_text:
          "You do not have the permission to access this page.",
        tokens_overview: "Token overview",
        my_portfolio: "My portfolio",
        my_transactions: "My transactions",
        seller_contract_form:
          "Filling out of the form for electronic agreement has not been finished. Are you sure you want to leave the page? ",
        electronic_contract_data: "Electronic agreement data ",
      },
      commissions: {
        waiting_for_payout: "Waiting for the payout",
        commission_paid: "Commission paid",
        waiting_for_manual_control: "Waiting for manual control",
      },
      translations: {
        funding_source: {
          employed: "Income from employment",
          self_employed: "Income from  self-employment",
          rentals: "Income from lease",
          property_settlement: "Income from property settlement",
          capital_assets: "Income from capital assets",
          other_income: "Other income not listed obove ",
        },
        employment_sector: {
          education: "Education sector",
          armed_forces: "Armed forces sector",
          health_care: "Health care sector",
          transport: "Transport sector",
          public_administration: "Public administration sector",
          finance_insurance: "Finacial insuarance sector",
          telecommunications: "Telecommunication sector",
          energy: "Energetic sector",
          itc: "ITC sector",
          industrial: "Industrial sector",
          business: "Business sector",
          agricultural: "Agricaltural sector",
        },
      },
    },
  },
};

let language;

if (localStorage.getItem("xixoio-language")) {
  language = localStorage.getItem("xixoio-language");
  switch (language) {
    case "cs":
    case "sk":
      language = "cs";
      break;
    default:
      language = "en";
  }
} else {
  switch (navigator.language) {
    case "cs":
    case "cs-CZ":
    case "sk":
    case "sk-SK":
      language = "cs";
      break;
    default:
      language = "en";
  }
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: language,
    fallbackLng: "cs",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

// catch the event and make changes accordingly
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("xixoio-language", lng);
  window.location.reload();
});

export default i18n;
