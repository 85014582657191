import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import { Icon } from "@xixoio/components/Icon";
import { NotificationsPanel } from "@xixoio/components/Notifications";
import classNames from "classnames";
import i18n from "i18n";
import PropTypes from "prop-types";

import {
  IAMExpirationNotification,
  IAMIncompleteNotification,
  OTPNotSetNotification,
  PendingTransactionsNotification,
  PhishingNotification,
  ReferralProgramPromoNotification,
  SellerAgreementSignaturePendingNotification,
  SellerDetailsMissingNotification,
} from "components/user-notifications";
import { GET_PENDING_TRANSACTIONS } from "queries/transactions";
import { GET_ME } from "queries/user";
import { useTimedHide } from "utils/hooks";

const expiresSoon = (amlExpiresAt, days = 30) => {
  if (!amlExpiresAt) {
    return false;
  }
  const exiresDate = new Date(amlExpiresAt);
  const controlDate = new Date();
  if (exiresDate < controlDate) return false;
  controlDate.setDate(controlDate.getDate() + days);
  return exiresDate < controlDate;
};

const LS_KEY_AML = "AML_EXPIRING_SHOW_AFTER_TIMESTAMP";
const LS_KEY_PENDING = "PENDING_TRANSACTIONS_SHOW_AFTER_TIMESTAMP";
const LS_KEY_PHISHING = "PHISHING_SHOW_AFTER_TIMESTAMP";
const LS_KEY_2FA = "2FA_SHOW_AFTER_TIMESTAMP";
const LS_KEY_PROMO = "REFERRAL_PROMO_SHOW_AFTER_TIMESTAMP";
const LS_KEY_AUTOOPEN = "NOTIFICATIONS_AUTOOPEN_DISABLED_UNTIL_TIMESTAMP";

const UserNotificationsContainer = ({
  opened,
  onOpen,
  onClose,
  className,
  canAutoOpen = false,
}) => {
  const { keycloak } = useKeycloak();

  const [isAMLExpirationHidden, hideAMLExpiration] = useTimedHide(LS_KEY_AML);
  const [isPendingHidden, hidePending] = useTimedHide(LS_KEY_PENDING, {
    days: 1,
  });
  const [isPhishingHidden, hidePhishing] = useTimedHide(LS_KEY_PHISHING);
  const [is2FAHidden, hide2FA] = useTimedHide(LS_KEY_2FA);
  const [isReferralPromoHidden, hideReferralPromo] = useTimedHide(LS_KEY_PROMO);

  const { data: meData, loading: meLoading } = useQuery(GET_ME);
  const { data: pendingData, loading: pendingLoading } = useQuery(
    GET_PENDING_TRANSACTIONS
  );
  const [autoOpenDisabled, disableAutoOpen] = useTimedHide(LS_KEY_AUTOOPEN, {
    hours: 1,
  });
  const [autoOpened, setAutoOpened] = useState(false);
  const [userInfo, setUserInfo] = useState(keycloak.userInfo);

  useEffect(() => {
    if (!userInfo) {
      keycloak.loadUserInfo().then(setUserInfo);
    }
  }, [keycloak, userInfo]);

  const me = !meLoading && meData.me;
  const pendingTransactions =
    !pendingLoading &&
    pendingData?.me &&
    pendingData.me.transactions.edges.map((edge) => edge.node);
  const otpSetupUrl = keycloak
    .createAccountUrl()
    .replace("my-xixoio/account", "my-xixoio/account/totp");

  const notifications = [
    {
      visible: me && me.verificationStatus !== "APPROVED",
      onHide: null,
      render: (key) => (
        <IAMIncompleteNotification
          verificationStatus={me.verificationStatus}
          key={key}
        />
      ),
    },
    {
      visible: me && expiresSoon(me.amlExpiresAt) && !isAMLExpirationHidden,
      onHide: hideAMLExpiration,
      render: (key) => (
        <IAMExpirationNotification expiresAt={me.amlExpiresAt} key={key} />
      ),
    },
    {
      visible: me && me.sellerAgreementState === "SELLER_DETAILS_PENDING",
      onHide: null,
      render: (key) => (
        <SellerDetailsMissingNotification
          contractFormUrl="/seller-contract-form/"
          key={key}
        />
      ),
    },
    {
      visible: me && me.sellerAgreementState === "SIGNATURE_PENDING",
      onHide: null,
      render: (key) => (
        <SellerAgreementSignaturePendingNotification key={key} />
      ),
    },
    {
      visible:
        pendingTransactions &&
        pendingTransactions.length > 0 &&
        !isPendingHidden,
      onHide: hidePending,
      render: (key) => (
        <PendingTransactionsNotification
          transactions={pendingTransactions}
          key={key}
        />
      ),
    },
    {
      visible: me && !isPhishingHidden,
      onHide: hidePhishing,
      render: (key) => <PhishingNotification key={key} />,
    },
    {
      visible: userInfo && !userInfo.has_otp_configured && !is2FAHidden,
      onHide: hide2FA,
      render: (key) => (
        <OTPNotSetNotification setupUrl={otpSetupUrl} key={key} />
      ),
    },
    {
      visible: me && me.canReferOtherClients && !isReferralPromoHidden,
      onHide: hideReferralPromo,
      render: (key) => <ReferralProgramPromoNotification key={key} />,
    },
  ];
  const visibleNotifications = notifications.filter((notif) => notif.visible);
  const highPrioNotifications = visibleNotifications.filter(
    (notif) => !notif.onHide
  );

  const hideAll = () => {
    visibleNotifications.forEach((notif) => notif.onHide && notif.onHide());
    onClose();
  };

  useEffect(() => {
    if (
      canAutoOpen &&
      !opened &&
      !autoOpenDisabled &&
      highPrioNotifications.length > 0
    ) {
      disableAutoOpen();
      setAutoOpened(true);
      onOpen();
    }
  }, [
    canAutoOpen,
    opened,
    autoOpenDisabled,
    onOpen,
    disableAutoOpen,
    highPrioNotifications.length,
  ]);

  const handleClick = () => {
    opened ? onClose() : onOpen();
    setAutoOpened(false);
  };

  return (
    <div className={classNames("notifications", className)}>
      <button
        className="notifications__indicator icon-hoverable"
        onClick={handleClick}
      >
        <Icon icon="bell" className="icon-hoverable--not-hovered" />
        <Icon icon="bell-filled" className="icon-hoverable--hovered" />

        {visibleNotifications.length > 0 && (
          <div className="notifications__indicator-bullet" />
        )}
      </button>
      <NotificationsPanel
        texts={{
          title: i18n.t("notifications.title"),
          markAllAsRead: i18n.t("notifications.mark_all_as_read"),
          noNotifications: i18n.t("notifications.no_notifications"),
        }}
        notifications={
          autoOpened ? highPrioNotifications : visibleNotifications
        }
        opened={opened}
        hideAll={hideAll}
      />
    </div>
  );
};

UserNotificationsContainer.propTypes = {
  className: PropTypes.string,
  opened: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  canAutoOpen: PropTypes.bool,
};

export default UserNotificationsContainer;
