import { useQuery } from "@apollo/client";
import { Role } from "enums";
import PropTypes from "prop-types";

import { GET_ME } from "queries/user";

import * as validators from "./validators";

export const IfRole = ({ role, children }) => {
  const { loading, data } = useQuery(GET_ME);
  return !loading && data && validators.hasRole(role)(data.me)
    ? children
    : null;
};

export const IfNotRole = ({ role, children }) => {
  const { loading, data } = useQuery(GET_ME);
  return !loading && data && !validators.hasRole(role)(data.me)
    ? children
    : null;
};

IfRole.propTypes = IfNotRole.propTypes = {
  role: PropTypes.oneOf(Object.values(Role)),
  children: PropTypes.node,
};

export default IfRole;
