export const formatMoney = (() => {
  // define this inside a closure to hide it from world
  let cachedFormatters = {};

  // this is the real formatMoney function
  return (amount, currency) => {
    if (!cachedFormatters.hasOwnProperty(currency)) {
      // create formatter for this currency, it's the first time we need it
      cachedFormatters[currency] = new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
      });
    }
    return cachedFormatters[currency].format(amount);
  };
})();

export const customFormatMoney = (amount, currency, options) => {
  const opts = Object.assign(
    {
      style: "currency",
      currency: currency,
    },
    options
  );
  const formatter = new Intl.NumberFormat("cs-CZ", opts);
  return formatter.format(amount);
};

export const formatDecimal = (() => {
  const formatter = new Intl.NumberFormat("cs-CZ");

  return (amount) => {
    return formatter.format(amount);
  };
})();

export const formatCurrencySymbol = (currency) =>
  formatMoney(0, currency).replace(/[\s\d.,-]+/, "");

const tokenFormatter = new Intl.NumberFormat("cs-CZ", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatTokenAmount = (amount, token) => {
  const amountStr = tokenFormatter.format(amount);
  return token ? `${amountStr} ${token}` : amountStr;
};

const plusSignIfPositive = (number, text, precision = 2) => {
  // Enforce sign not only for negative numbers but also for the positive ones.
  // We want to avoid printing signed zeros (+0, -0) for numbers out of the
  // displayed precision. This happens too much often because of rounding
  // errors - eg. right after the purchase, current profit can be something
  // like 0.00000000000016. Which is really small and rounded to 0 on UI, but
  // still positive.
  const roundedNumber = parseFloat(parseFloat(number).toFixed(precision));

  // add plus sign carefully
  if (roundedNumber > 0) {
    return "+".concat(text);
  }

  // and also fix `Intl.NumberFormat` outputs for negative numbers if needed
  if (roundedNumber === 0 && text && text[0] === "-") {
    return text.substring(1);
  }
  return text;
};

export const formatProfit = (amount, currency) => {
  return plusSignIfPositive(amount, formatMoney(amount, currency));
};

const PERCENTS_FRACTION_DIGITS = 1;

const percentFormatter = (maxFractionDigits) =>
  new Intl.NumberFormat("cs-CZ", {
    style: "percent",
    maximumFractionDigits: maxFractionDigits,
  });

export const formatPercent = (
  percent,
  maxFractionDigits = PERCENTS_FRACTION_DIGITS
) => {
  // For number like 0.012 outputs string +1.2 %. Again we have to be carefull
  // about the sign to avoid printing signed zeros (+0, -0).
  // To get 1 fraction digit precision in percent form (1.2 %), we need to
  // work with 3 fraction digit precision in decimal format (0.012).
  return plusSignIfPositive(
    percent,
    percentFormatter(maxFractionDigits).format(percent),
    PERCENTS_FRACTION_DIGITS + 2
  );
};

export const formatDatetime = (datetime, short = false) => {
  const datetimeDate = datetime.toLocaleDateString("cs-CZ", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const datetimeTime =
    datetime.getHours() +
    ":" +
    datetime.getMinutes().toString().padStart(2, "0");
  return short ? `${datetimeDate}` : `${datetimeDate}, ${datetimeTime}`;
};

export const formatMonth = (dateOrStr) => {
  const date = new Date(dateOrStr);
  return date.toLocaleDateString("cs-CZ", { month: "long" });
};

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);
