import gql from "graphql-tag";

export const LIST_TOKENS = gql`
  query ListTokens(
    $currency: Currency = CZK
    $calculateHistory: Boolean = false
    $clientId: ID!
  ) {
    tokens {
      name
      project {
        title
        url
        description
        descriptionEn
      }
      orderLimits(currency: $currency, clientId: $clientId) {
        minValue
        maxValue
        tokensLeftFiat
      }
      tokensLeftInTranche
      trancheSize
      progressbarInfo
      progressbarInfoEn
      progressbarText
      progressbarTextEn
      type
      tokenInformation
      tokenInformationEn
      isActive
      hasTransactionHistory(calculateHistory: $calculateHistory)
      value(currency: $currency)
      xixoutUrl
    }
  }
`;

export const LIST_TOKEN_VALUE_HISTORY = gql`
  query ListTokenValueHistory(
    $tokenName: ID!
    $from: DateTime
    $until: DateTime
  ) {
    token(name: $tokenName) {
      name
      valueHistory(datetimeFrom: $from, datetimeUntil: $until) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            value
            datetime
          }
        }
      }
    }
  }
`;

export const tokenDetailsFragment = gql`
  fragment TokenDetails on TokenType {
    name
    isActive
    project {
      title
      url
      description
    }
    tokenInformation
    orderLimits(currency: $currency, clientId: $id) {
      minValue
      maxValue
      tokensLeftFiat
    }
    xixoutUrl
    __typename
  }
`;

export const TOKEN_REF_BONUS = gql`
  query TokenRefBonus($tokenName: ID!) {
    token(name: $tokenName) {
      name
      referralBonusAmount
    }
  }
`;
