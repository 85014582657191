import gql from "graphql-tag";

export const MAKE_PAYMENT = gql`
  mutation MakePayment(
    $fiatAmount: DecimalScalar!
    $transactionFeePercent: DecimalScalar!
    $token: String!
    $fiatCurrency: Currency!
    $expectedTokenAmount: DecimalScalar!
    $expectedAmountPrecision: Int!
    $onBehalfOf: ID
  ) {
    createTransaction(
      fiatAmount: $fiatAmount
      fiatCurrency: $fiatCurrency
      transactionFeePercent: $transactionFeePercent
      token: $token
      expectedTokenAmount: $expectedTokenAmount
      expectedAmountPrecision: $expectedAmountPrecision
      onBehalfOf: $onBehalfOf
    ) {
      transaction {
        id
        transactionFeePercent
        transactionFee
        totalCost
        client {
          id
        }
        initiatedBy {
          id
        }
        payment {
          referenceNumber
          account {
            czech {
              prefix
              account
              bankCode
            }
            iban
          }
        }
      }
    }
  }
`;

export const GET_RATE = gql`
  query Valuation($token: String!, $currency: Currency!) {
    valuation(token: $token, currency: $currency)
    transactionPurchaseFeePercent(token: $token)
  }
`;
