import React from "react";
import PropTypes from "prop-types";

export const IfAppFeature = ({ children, feature }) => {
  const FEATURES = process.env.REACT_APP_FEATURES;

  return <>{FEATURES?.split(",")?.includes(feature) && children}</>;
};

IfAppFeature.propTypes = {
  children: PropTypes.node,
  feature: PropTypes.string.isRequired,
};

export default IfAppFeature;
