import React from "react";
import { Button } from "@xixoio/components/Button";
import { Notification } from "@xixoio/components/Notifications";
import classNames from "classnames";
import i18n from "i18n";

const IAMIncompleteNotification = ({ verificationStatus }) => {
  const iconClassName = classNames({
    warning: verificationStatus !== "DECLINED",
    danger: verificationStatus === "DECLINED",
  });

  const cta = () => {
    let step =
      (verificationStatus === "NOT_DONE" && "intro") ||
      (verificationStatus === "EXPIRED" && "intro?reissue=yes");

    return (
      <>
        {step && (
          <Button
            routerTo={"/identity-verification/" + step}
            secondary
            small
            textCenter
            className="whitespace-nowrap"
          >
            {i18n.t("notifications.IAM_incomplete.verify_identity")}
          </Button>
        )}
      </>
    );
  };

  return (
    <Notification
      warning={
        verificationStatus === "NOT_DONE" || verificationStatus === "PENDING"
      }
      danger={
        verificationStatus === "DECLINED" || verificationStatus === "EXPIRED"
      }
      icon={iconClassName}
      cta={cta()}
    >
      <>
        {verificationStatus === "NOT_DONE" && (
          <>{i18n.t("notifications.IAM_incomplete.text_1")}</>
        )}
        {verificationStatus === "PENDING" && (
          <>
            {i18n.t("notifications.IAM_incomplete.text_2")}{" "}
            <a
              href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`}
              className="link text-yellow-400"
            >
              {i18n.t("notifications.IAM_incomplete.text_3")}
            </a>
            .
          </>
        )}
        {verificationStatus === "DECLINED" && (
          <>
            {i18n.t("notifications.IAM_incomplete.text_4")}{" "}
            <a
              href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`}
              className="link text-red-400"
            >
              {i18n.t("notifications.IAM_incomplete.text_3")}
            </a>
            .
          </>
        )}
        {verificationStatus === "EXPIRED" && (
          <>{i18n.t("notifications.IAM_incomplete.text_5")}</>
        )}
      </>
    </Notification>
  );
};

export default IAMIncompleteNotification;
