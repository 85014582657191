import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { prefix } from "../../data.js";

export const Icon = ({ type, icon, hoverable, className, ...props }) => {
  const Tag = type ? type : "i";

  return hoverable ? (
    <span className="icon-hoverable">
      <Tag
        className={classNames(
          "icon-hoverable--not-hovered",
          prefix + icon,
          className
        )}
        {...props}
      />
      <Tag
        className={classNames(
          "icon-hoverable--hovered",
          prefix + icon + "-filled",
          className
        )}
        {...props}
      />
    </span>
  ) : (
    <Tag className={classNames(prefix + icon, className)} {...props} />
  );
};

Icon.propTypes = {
  type: PropTypes.string,
  hoverable: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default React.memo(Icon);
