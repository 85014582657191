import React, { Suspense } from "react";
import PropTypes from "prop-types";

const TransactionDetailProvider = React.lazy(() =>
  import(
    "containers/transactions/TransactionDetailProvider" /* webpackChunkName: "TransactionDetailProvider" */
  )
);

const TransactionDetailModal = ({ transactionId, onClose }) => (
  <Suspense fallback={<></>}>
    <TransactionDetailProvider
      transactionId={transactionId}
      onClose={onClose}
    />
  </Suspense>
);

TransactionDetailModal.path = "/transaction/:transactionId";

TransactionDetailModal.composePath = (transaction) =>
  `transaction/${transaction.id}`;

TransactionDetailModal.propTypes = {
  transactionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TransactionDetailModal;
