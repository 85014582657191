import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { CircleIcon } from "../CircleIcon";

const Notification = ({ className, icon, warning, danger, cta, children }) => {
  const cls = classNames(
    "notifications__list-item",
    { "notifications__list-item--warning": warning },
    { "notifications__list-item--danger": danger },
    className
  );

  return (
    <li className={cls}>
      <div className="notifications__list-item-content">
        {icon && (
          <CircleIcon
            className="notifications__list-item-icon"
            icon={icon}
            aria-hidden="true"
          />
        )}
        <p>{children}</p>
      </div>
      {cta && <div className="notifications__list-item-cta">{cta}</div>}
    </li>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  cta: PropTypes.node,
  children: PropTypes.node,
};

export default React.memo(Notification);
