import TagManager from "react-gtm-module";
import keycloak from "keycloak";

const getUserType = (tokenParsed) => {
  const roles = tokenParsed.realm_access.roles;
  if (roles.includes("seller")) {
    return "salesrep";
  }
  return "customer";
};

export const dataLayer = ({
  event = "pageview",
  eventProps = {},
  dataLayerName = "PageDataLayer",
  ...props
} = {}) => {
  const tokenParsed = keycloak && keycloak.tokenParsed;
  const userInfo = tokenParsed ? { userId: keycloak.tokenParsed.sub } : {};

  TagManager.dataLayer({
    dataLayer: {
      event,
      eventProps,
      page: {
        url:
          document.location.origin +
          document.location.pathname +
          document.location.search,
        title: !!window && window.title,
      },
      ...userInfo,
      ecomm_usertype: getUserType(tokenParsed),
      ...props,
    },
  });
};

// ecommerce event (data) has certain variable set and rules defined in documentation (e. g. ecommerce_usertype is missing, page, etc.).
// this function will be probably extended once we receive more detailed requirements
export const blankDataLayer = ({ ...props }) => {
  TagManager.dataLayer({
    dataLayer: {
      ...props,
    },
  });
};
