import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Avatar = ({ image, name, fallback, className, size }) => {
  const hasImage = !!image;
  const bits = name ? name.split(" ") : fallback.split("");

  const initials = bits
    .filter((bit) => !!bit)
    .map((bit) => bit[0].toUpperCase())
    .slice(0, 2)
    .join("");
  const spanCls = classNames("avatar__placeholder", className);
  const imgCls = classNames("avatar__image", className);

  const sizeProps = {};

  if (size) {
    sizeProps.width = sizeProps.height = size;
  }

  return (
    <>
      {!hasImage && (
        <div className={spanCls}>
          <span aria-label={(name || fallback) + " avatar"}>{initials}</span>
        </div>
      )}
      {hasImage && (
        <img
          className={imgCls}
          src={image}
          alt={(name || fallback) + " avatar"}
          aria-label={(name || fallback) + " avatar"}
          {...sizeProps}
        />
      )}
    </>
  );
};

Avatar.propTypes = {
  fallback: PropTypes.string.isRequired,
  name: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
};

export default React.memo(Avatar);
