import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useHistoryCallback = (fn, deps = []) => {
  const navigate = useNavigate();

  /* eslint-disable react-hooks/exhaustive-deps */
  return useCallback(() => {
    fn(navigate);
  }, [navigate, ...deps]);
};
