import classNames from "classnames";
import i18n from "i18n";
import PropTypes from "prop-types";

const LanguageSwitch = ({ className }) => {
  return (
    <div className={classNames("notifications", className)}>
      <button
        className="notifications__indicator icon-hoverable"
        onClick={() => {
          i18n.changeLanguage(i18n.language === "cs" ? "en" : "cs");
        }}
      >
        {i18n.language === "cs" ? <div>ENG</div> : <div>CZ</div>}
      </button>
    </div>
  );
};

LanguageSwitch.propTypes = {
  className: PropTypes.string,
};

export default LanguageSwitch;
